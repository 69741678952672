import { css } from "@emotion/react";
import { typography,colors } from "../../../../scss/colors";

export const PayoutMainHeadding = css`
  color: ${colors.lightBlack};
  ${typography('600', '24px')};
  margin-bottom: 20px;
`;

export const payoutSelect = css`
  display: flex;
  justify-content: start;
  padding:20px 0px;
`;
