/** @jsxImportSource @emotion/react */
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import { useState } from "react";
import { StringConstants } from "../../constants/Strings";
import {  VeirfyMainHeadding, payoutMainSection } from "../ApplicationReview/InitiateKyc/styles/InitiateKyc.style";
import CustomTable from "../../components/Table/Table";
import { css } from "@emotion/react";

const PayoutDetailsContainer = css`
margin-top:10px;
`
const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'Location', label: 'Location', minWidth: 100 },
  { id: 'stage', label: 'Stage', minWidth: 100 },
  { id: 'product', label: 'Product', minWidth: 100 },
  { id: 'date', label: 'Disbursed Date', minWidth: 100 },
];

const data = [
  { ApplicationNo: '12345', Location: "Bangalore", stage: 'Under Review', product: 'Ev', Name: 'Terry Ekstrom Bothman', date: '13/02/2023' },
  { ApplicationNo: '12346', Location: "Bangalore", stage: 'Sanctioned', product: 'RTS', Name: 'Terry Ekstrom Bothman', date: '11/01/2023' },
  { ApplicationNo: '12347', Location: "Mangalore", stage: 'Sanctioned', product: 'Rooftop Solar', Name: 'Terry Ekstrom Bothman', date: '03/12/2023' },
  { ApplicationNo: '12348', Location: "Delhi", stage: 'Under Review', product: 'EV', Name: 'Terry Ekstrom Bothman', date: '22/09/2023' },
  { ApplicationNo: '12349', Location: "Chennai", stage: 'Under Review', product: 'RTS', Name: 'Terry Ekstrom Bothman', date: '10/02/2023' },
  { ApplicationNo: '12350', Location: "Mumbai", stage: 'Under Review', product: 'Rooftop Solar', Name: 'Terry Ekstrom Bothman', date: '13/04/2023' },
];

const PayoutDetails = () => {
  const { PAYOUT_DETAILS } = StringConstants
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  return (
    <div>
      <DashboardLoyout>
        <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>{PAYOUT_DETAILS}</div>
        </div>
        <div css={PayoutDetailsContainer}>
          <CustomTable
            columns={columns}
            data={data}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            onlyTable={true}
            setRowsPerPage={setRowsPerPage}

          />
         </div>
      </DashboardLoyout>
   
    </div>
  );
};

export default PayoutDetails;
