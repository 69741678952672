import { css } from "@emotion/react";
import { typography,colors } from "../../scss/colors";

export const containerStyle = css`
  color: ${colors.charcoal};
  ${typography('600', '20px')};
`;

export const breakLine = css`
  border: 1px solid rgba(100, 130, 130, 0.25);
  margin-bottom: 25px;
`;

export const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
export const formControlStyle = css`
margin-bottom: 16px;
width: 100%;
`;

export const bankSaveStyle = css`
  margin-top: 22px;
  text-align: left;
  display: flex;
  align-items: center;
   @media(max-width:768px){
  flex-direction:column;
    margin-left: 0px;
}
`;



export const saveValidateButtonStyle = (props: { disabled: boolean }) => css`
  background-color: ${colors.white};
  color: ${colors.crimson};
  padding: 10px 20px;
  border: 1px solid ${colors.crimson};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props.disabled ? 0.5 : 1}; 
  ${typography('bold', '16px')};
  @media(max-width:768px){
width: 100%;
}
  &:disabled {
    background-color: white;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: ${colors.crimson};
    color: ${colors.white};
  }
`;

export const successMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

export const errorMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

export const messageContainerStyle = css`
  display: flex;
  align-items: center;
  margin-left: 15px;
 @media(max-width:768px){
    margin-top: 20px;
}
`;

export const bankContainer = css`
padding:0px 10px 0px 20px;
@media(max-width:768px){
padding:0px;
}
`