import { css } from "@emotion/react";
import { colors,typography } from "../../../../scss/colors";

export const sanctionSection = css`
text-align:left;
`;

export const SanctionLabel = css`
color: ${colors.lightBlack};
${typography('600', '18px')};
margin-bottom:10px;
`;

export const sanctionApproved = css`
color: ${colors.crimson};
${typography('600', '18px')};
`;

export const commentLabel = css`
color: ${colors.tableLabel};
${typography('500', '16px')};
width:9%;
`;

export const commentValue = css`
color: ${colors.tableLabel};
${typography('400', '16px')};
width:91%;
`;

export const commentSection = css`
display:flex;
width:100%;
margin-bottom:20px;
`;