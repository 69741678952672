import { css } from "@emotion/react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '1px',
  });
  
  export const UploadCustomButton = styled(Button)({
    width: '200px',
    height: '60px',
    color: '#e12c36',
    borderColor: '#e12c36',
    '&:hover': {
      borderColor: '#e12c36',
      backgroundColor: '#ffe6e6',
    },
  });
  
  export const multifileSection = css`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 30px;
    width:100%;
    margin-bottom:30px;
    @media (max-width: 768px) {
      flex-direction:column;
      justify-content: unset;
      align-items: unset;
      }
  `;
  export const esignSection=css`
  display: flex;
  margin: 10px;
  width:50%;
  flex-direction: column;
  align-items: flex-start;
  
  `;
  export const fileListContainer = css`
  width:70%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    @media (max-width: 768px) {
      margin-left: unset;
      padding-top:20px;
  
      }
  `;
  export const fileContainer = css`
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    margin-bottom: 16px;
    @media (max-width: 768px) {
      width: 100%; 
      margin-bottom: 10px;
    }
  
  `;
  export const fileItems = css`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
    width: calc(100% - 30px); 
    margin-right: 10px; 
    justify-content:space-between;
    &:nth-of-type(2n) {
      margin-right: 0; 
    }
  `;
  export const fileItem = css`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
    width: calc(70% - ); 
    margin-right: 10px; 
    justify-content:space-between;
    &:nth-of-type(2n) {
      margin-right: 0; 
    }
  `;
  export const fileName = css`
    margin-right: 8px;
  `;
  export const buttonSection = css`
  width:20%;
  `
  
  export const marginLeftStyle = css`
  margin-left:-8px
  `