/** @jsxImportSource @emotion/react */
import {  useEffect, useState } from "react";
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import RegistrationDetails from "../../ApplicationReview/InitiateKyc/RegistrationDetails";
import DirectorDetails from "../../ApplicationReview/InitiateKyc/DirectorDetails";
import CompanyDetails from "../../ApplicationReview/InitiateKyc/CompanyDetails";
import BankDetails from "../../ApplicationReview/InitiateKyc/BankDetails";
import KycDocumentes from "../../ApplicationReview/InitiateKyc/KycDetails";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { InitiateKycDetailsSection } from "../../ApplicationReview/InitiateKyc/styles/InitiateKyc.style";
import PayoutStructure from "./PayoutStructure";
import Sanction from "./Sanction";
import UploadCpv from "../../ApplicationReview/InitiateCpv/UploadCpv";
import { getLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { backCss, mobileNavigation } from "../../ApplicationReview/InitiateKyc/InitiateKycDetails";
import { icon } from "../../../assets/Images";
import IndividualDetails from "../../ApplicationReview/InitiateKyc/IndividualDetails";
import { useNavigate } from "react-router-dom";
import Loder from "../../../components/Loader/Loder";
import { ToastContainer } from "react-toastify";
import IdVerification from "../../ApplicationReview/InitiateKyc/IdVerification";
import Cibil from "../../ApplicationReview/InitiateKyc/Cibil";
const Sanction1Details = (props: any) => {
  const navigate = useNavigate()
  const { showDetails,detailsData ,setShowDetails} = props;
  const filterByStatus=getLocalStorage('filterByStatus');
  const [value, setValue] = useState(0);
  const {registrationDetails,bankDetails,individualDetails,companyDetails,directorDetails}=detailsData.data;
  const [entityType, setEntityType] = useState('');
  const [partner, setPartner] = useState('');
  const breadcrumbItems = [
    { label: 'Sanction', href: '/sanction' },
    { label: showDetails.ApplicationNo }
  ];
  const {
    loadingComponent,
    visible,
} = Loder();
  

const handleNext = () => {
setValue(value + 1)
}

  const handleSanctionNext = () => {
    navigate('/sanction-2')
  }
  const handleUploadNext = () => {
    setValue(value + 1)
  }
  const Individualtabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails
          setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
          />

        </div>
      ),
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value} filterByStatus={filterByStatus}/></div>},
    { label: 'Sanction', content: <div><Sanction setShowDetails={setShowDetails} isSanction2Path={false} handleSanctionNext={handleSanctionNext} filterByStatus={filterByStatus}/></div>}

  ];

  const Proprietortabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails   setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails} filterByStatus={filterByStatus}/>
        </div>
      ),
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue}  details={companyDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value} filterByStatus={filterByStatus}/></div>},
    { label: 'Sanction', content: <div><Sanction  setShowDetails={setShowDetails} isSanction2Path={false} handleSanctionNext={handleSanctionNext} filterByStatus={filterByStatus}/></div>}

  ];
  const Businesstabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails    setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            filterByStatus={filterByStatus}
            />
        </div>
      ),
    },
    {
      label: 'Director Details',
      content:
        <div>
          <DirectorDetails setValue={setValue} details={directorDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId} contextId={showDetails?.ApplicationNo} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} registrationId={detailsData?.data?.registrationId} filterByStatus={filterByStatus}/>
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleKyc={handleNext} filterByStatus={filterByStatus}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext} filterByStatus={filterByStatus}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value} filterByStatus={filterByStatus}/></div>},
    { label: 'Sanction', content: <div><Sanction  setShowDetails={setShowDetails} isSanction2Path={false} handleSanctionNext={handleSanctionNext} filterByStatus={filterByStatus}/></div>}

  ];
  useEffect(() => {
    const fetchValues = () => {
      const latestEntityType = getLocalStorage('Application_EntityType');
      const latestPartner = getLocalStorage('Application_PartnershipType');
      setEntityType(latestEntityType);
      setPartner(latestPartner);
    };

    fetchValues();
  }, []);
  
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>     <div css={mobileNavigation}>
          <div css={backCss} onClick={() => setShowDetails(false)}>
            <img src={icon.NavigateBack} alt="no_navigate" />
          </div>
          <div>
          Sanction - {showDetails.ApplicationNo}
          </div>
      </div>
      <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={entityType?.includes("Individual")
            ? Individualtabs
            : (entityType?.includes("Business") && partner?.includes("Proprietorship"))
              ? Proprietortabs
              : Businesstabs} value={value} setValue={setValue}/>
      </div>
    </>}
    <ToastContainer />
    </div>
  );
};

export default Sanction1Details;
