/** @jsxImportSource @emotion/react */
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import { useState } from "react";
import { utilsConstants } from "../../utils/utilsConstants";
import { StringConstants } from "../../constants/Strings";
import Loder from "../../components/Loader/Loder";
import DashBoard from "./DynamicDashboard";
import { headerSectionArea, headdingDashbaord, FilterSectionArea, selectedDateFilter, selectAreaOption, ChartsContainer, Mobileheadding } from "./styles/Dashboard.style";


const Dashboard = () => {
  const { DASHBOARD_AREA_FILTER } = utilsConstants;
  const [DateFilter, setDateFilter] = useState(DASHBOARD_AREA_FILTER[0]);
  const { DASHBOARD, AMT_DIS } = StringConstants;
  const {
    loadingComponent,
    visible,
  } = Loder();

  const handleChangeFilter = (item: string) => {
    setDateFilter(item)
  }
  return (
    <div>
      <DashboardLoyout>
        {visible ?
          <>{loadingComponent()}</> :
          <>
            <div css={headerSectionArea}>
              <div css={headdingDashbaord}>{DASHBOARD}</div>
              <div css={FilterSectionArea}>
                {DASHBOARD_AREA_FILTER.map((status) => (
                  <div
                    key={status}
                    onClick={() => handleChangeFilter(status)}
                    css={DateFilter === status ? selectedDateFilter : selectAreaOption}
                  >
                    {status}
                  </div>
                ))}
              </div>
            </div>
            <div css={ChartsContainer}>
              <div css={Mobileheadding}>{AMT_DIS}.</div>
              <div>
                <DashBoard filter={DateFilter} />
              </div>

            </div>
          </>}
      </DashboardLoyout>
    </div>
  );
};

export default Dashboard;