/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import DashboardLoyout from "../../../components/DashbaordLayout/DashbaordLayout";
import CustomTable from "../../../components/Table/Table";
import { VeirfyMainHeadding } from "./styles/InitiateKyc.style";
import { StringConstants } from "../../../constants/Strings";
import InitiateKycDetails from "./InitiateKycDetails";
import { get, post } from "../../../apiManger/apiMethods";
import { getApplicationDetailsGETApiUrl, getApplicationListPOSTApiUrl } from "../../../apiManger/apiConstants";
import Loder from "../../../components/Loader/Loder";
import { deleteLocalStorage, setLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";

const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'PartnerType', label: 'Partner Type', minWidth: 100 },
  { id: 'EntityType', label: 'Entity Type', minWidth: 100 },
  { id: 'PartnershipType', label: 'Partnership Type', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'MobileNo', label: 'Mobile No', minWidth: 100 },
  { id: 'Details', label: 'Details', minWidth: 100 },
];
export const formatDate = (date: any) => {
  if (!date) return "";

  if (typeof date === "string" && date.includes(".")) {
    const [day, month, year] = date.split(".");
    return `${year}-${month}-${day}`;
  }

  const d = new Date(date);

  if (isNaN(d.getTime())) {
    return "";
  }

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
const InitiateKyc = () => {
  const { showToast } = useToastHook()
  const { VERIFY_KYC_HEADING } = StringConstants;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending");
  const [showDetails, setShowDetails] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    partnerType: [],
    entityType: [],
    partnerShipType: []
  });
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  setLocalStorage('filterByStatus', filterByStatus)
  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);
  const getAPICall = async () => {
    showLoadingIndicator()
    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.partnerType,
      entityTypeValue: filterByReg.entityType,
      partnershipTypeValue: filterByReg.partnerShipType,
      status: filterByStatus,
      stage: 'kyc'
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data?.length) {
      hideLoadingIndicator()
      const array = response.result.data.map((item: any) => ({
        ApplicationNo: item.applicationNumber || '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);


    } else {
      hideLoadingIndicator()
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleViewDetails = (rowData: any) => {
    deleteLocalStorage("individual_id")
    deleteLocalStorage("company_id")
    deleteLocalStorage("bank_id")
    deleteLocalStorage("director_id")
    fetchDetails(rowData)
  };
  const fetchDetails = async (rowData: any) => {
    showLoadingIndicator();
    const response = await get({
      url: getApplicationDetailsGETApiUrl(rowData.registrationId),

    });

    if (response?.result) {
      hideLoadingIndicator();
      setLocalStorage('Application_registrationId', rowData.registrationId)
      setDetailsData(response.result)
      setShowDetails(rowData);
      setLocalStorage("individual_id", response.result?.data?.individualDetails?.id)
    } else if (response?.error?.message) {
      showToast(response?.error?.message, "error")
      hideLoadingIndicator();
    }
    else {
      hideLoadingIndicator();
    }
  }
  const isMobile = window.innerWidth < 768;
  return (
    <div>
      {!showDetails ? (
        <DashboardLoyout>
          <div css={VeirfyMainHeadding}>{VERIFY_KYC_HEADING}</div>
          <CustomTable
            columns={columns}
            data={data}
            handleviewDetails={handleViewDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={true}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search}
            totalRecords={totalRecords}
            visible={visible}
            loadingComponent={loadingComponent}
          />
        </DashboardLoyout>
      ) : (
        <>
          {!isMobile && (
            <DashboardLoyout>
              <div css={VeirfyMainHeadding}>{VERIFY_KYC_HEADING}</div>
              <InitiateKycDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} filterByStatus={filterByStatus} />
            </DashboardLoyout>
          )}
          {isMobile && (
            <InitiateKycDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} filterByStatus={filterByStatus} />
          )}
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default InitiateKyc;


