/** @jsxImportSource @emotion/react */
import {  useState } from "react";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import MultiFileUpload from "./MultiFileUpload";
import Loder from "../../components/Loader/Loder";
import { backCss, mobileNavigation } from "../ApplicationReview/InitiateKyc/InitiateKycDetails";
import { icon } from "../../assets/Images";
import { css } from "@emotion/react";

const EsignDetails = (props: any) => {
  const { showDetails ,detailsData,setShowDetails} = props;
  const [value, setValue] = useState(0);
  const {
    loadingComponent,
    visible,
} = Loder();
  const breadcrumbItems = [
    { label: 'E-Sign', href: '/e-sign' },
    { label: showDetails?.data?.registrationDetails?.applicationNumber }
  ]
  const tabs = [
    {
      label: 'File Upload',
      content: (
        <div>
          <MultiFileUpload setShowDetails={setShowDetails} detailsData={detailsData?.data?.registrationDetails}/>
        </div>
      ),
    },
    
  ];
  const InitiateKycDetailsSection = css`
  margin-top:20px;
  @media (max-width: 768px) {
padding:20px;
}
  `;
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>  
        <div css={mobileNavigation}>
        <div css={backCss} onClick={() => setShowDetails(false)}>
          <img src={icon.NavigateBack} alt="no_navigate" />
        </div>
        <div>
          Esign - {showDetails?.data?.registrationDetails?.applicationNumber}
        </div>
      </div>
        <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={tabs} value={value} setValue={setValue}/>
      </div>
      </>}
    </div>
  );
};

export default EsignDetails;
