/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import CustomInput from "../../components/Input/Input";
import { regBackButton,registrationButtonContainer } from "../Registration/Registration.style";
import { ValidName,isMobileValid,validEmail, validateAadhaar, validatePAN } from "../../constants/Validations";
import { StringConstants } from "../../constants/Strings";
import CustomButton from "../../components/Button/Button";
import { get } from "../../apiManger/apiMethods";
import { getProfileGETApiUrl } from "../../apiManger/apiConstants";
import Loder from "../../components/Loader/Loder";
import { ToastContainer } from 'react-toastify';
import { Box, Grid } from '@mui/material';
import { css } from '@emotion/react';
import { useNavigate } from "react-router-dom";

const Profile = (props:any) => {
    const navigate = useNavigate()
    const {BACK_LABEL}  = StringConstants;
    const [individualDetails, setIndividialDetail] = useState<any>({
        firstName: "",
        lastName: "",
        mobileNo: "",
        emailId: "",
        gender:"",
        panNo: "",
        aadharNo: "", 
        registrationId:""
    })
    const [errors, setErrors] = useState({});
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
      } = Loder();
    useEffect(() => {
        const fetchProfileData = async () => {
            showLoadingIndicator()
            try {
               
                    const response = await get({
                      url: getProfileGETApiUrl()
                    });
                    if (response?.result) {
                        hideLoadingIndicator()
                        setIndividialDetail({
                            firstName: response.result.firstName || "",
                            lastName: response.result.lastName || "",
                            mobileNo: response.result.mobileNumber || "",
                            emailId: response.result.emailId || "",
                            gender: response.result.gender || "",
                            panNo: response.result.panNumber || "",
                            aadharNo: response.result.aadharNumber || "",
                            dateOfBirth: response.result.dob || "",
                            registrationId:response.result.registrationId||""
                        });
                    }
                    else{
                        hideLoadingIndicator();
                    }
                  } catch (error) {
                    hideLoadingIndicator();
                    console.error("Error fetching product vs applications data:", error);
                  }
              
        
    }

        fetchProfileData();
    }, []);
   
    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'firstName':
            case 'lastName':
                return ValidName;
            case 'mobileNo':
                return isMobileValid;
            case 'emailId':
                return validEmail;
            case 'panNo':
                return validatePAN
            case 'aadharNo':
                return validateAadhaar
           
            default:
                return () => null;
        }
    };

    const handleChange = (fieldName: any, value: any) => {
       
        setIndividialDetail({
            ...individualDetails,
            [fieldName]: value
        });
        const validatorFn = getValidator(fieldName);
        const error = validatorFn(value);
        setErrors({
            ...errors,
            [fieldName]: error
        });
    };
  
    const gridContainerStyle = css`
    width: calc(100% + 30px);
    @media(max-width:768px){
  width: calc(100% + 20px);
 }
    `;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return(
        <>
        {visible ? 
        <>{loadingComponent()}</> :
        <> 
        <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                <Grid container spacing={2} css={gridContainerStyle}>
                <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="First Name"
                        value={individualDetails.firstName}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter First Name'
                        mandatory={true}
                        validate={ValidName}
                        inputProps={{ maxLength: 26 }}
                    />
                     </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Last Name"
                        value={individualDetails.lastName}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Last Name'
                        mandatory={true}
                        validate={ValidName}
                        inputProps={{ maxLength: 26 }}
                    />
                       </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Email ID"
                        value={individualDetails.emailId}
                        onChange={(e) => handleChange('emailId', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Email ID'
                        mandatory={true}
                        validate={validEmail}
                    />
                     </Grid>
                     </Grid>
                     </Box>
        <div css={registrationButtonContainer}>
                    <CustomButton
                        label={BACK_LABEL}
                        onClick={() => navigate('/dashboard')}
                        variant="outlined"
                        css={regBackButton}
                    />
                </div>
                <ToastContainer />
                </>}
        </>
    )
}
export default Profile;