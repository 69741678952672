/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { getRenderData } from "../services/highcharts.service";
import { Chart } from "../../components/HighchartsChart/chart";
import { Table } from "../../components/HighchartsTable/table";
import { Card } from "../../components/HighchartsCards/card";
import Loder from "../../components/Loader/Loder";
import { noDataContainer, cardGridContainer, CardContainer, chartsTablesGridContainer, chartsLeftSection } from "./styles/DynamicDashboard.style";

const DashBoard = (props:any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [renderData, setRenderData] = useState<any[]>([]);
  const {filter}=props;
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  useEffect(() => {
    getDashBoardData();
  }, [filter]);

  const getDashBoardData = async () => {
    try {
      showLoadingIndicator();
      const data = await getRenderData(filter);
      setRenderData(data); 
      setLoading(false); 
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    } finally {
      hideLoadingIndicator();
    }
  };
const ContainerWidth = css`
width:75vw
`
  return (
    <div css={ContainerWidth}>
    {visible || loading? (
      <>{loadingComponent()}</>
    ) : renderData?.length === 0 ? (
      <div css={noDataContainer}>No Data Available</div>
    ) : (
      <>
        <div css={cardGridContainer}>
          {renderData?.length && renderData?.map((dataItem, index) => {
            const renderTypes = dataItem.render_config.render_types || [];
            return renderTypes.includes("card") ? (
              <div css={CardContainer} key={index}>
                <Card data={dataItem} />
              </div>
            ) : null;
          })}
        </div>

        <div css={chartsTablesGridContainer}>
          {renderData?.length && renderData?.map((dataItem, index) => {
            const renderTypes = dataItem.render_config.render_types || [];
            return (
              <React.Fragment key={index}>
                {renderTypes.includes("chart") && (
                  <div css={chartsLeftSection}>
                    <Chart item={dataItem} index={index} />
                  </div>
                )}
                {renderTypes.includes("table") && (
                  <div css={chartsLeftSection}>
                    <Table item={dataItem} />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </>
    )}
  </div>
);
};

export default DashBoard;
