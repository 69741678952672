export const utilsConstants = {
    STEPPER: ['Registration', 'Individual Details', 'Company Details', 'Bank Details','KYC Documents'],
    STEPPER_INDIVIDUAL: ['Registration', 'Individual Details',  'Bank Details','KYC Documents'],
  
    RegisterTypeOptions : [
      {value:"Partner",label:"Partner"} ,
      ] ,
    PartnerTypeOptions : [{value:"DSA/E-DSA",label:"DSA/E-DSA"} ,
    ],
    EntityTypeOptions : [{value:"Individual",label:"Individual"},{value:"Business Entity",label:"Business Entity"}],
    PartnerShipTypeOptions : [{value:"Sole Proprietorship",label:"Sole Proprietorship"},{value:"Partnership",label:"Partnership"},{value:"LLP",label:"LLP"},{value:"Public Ltd",label:"Public Ltd"},{value:"Private Ltd",label:"Private Ltd"}],
    STEPPER_INDIVIDUAL_DIRECTOR :  ['Registration', 'Company Details','Director Details', 'Bank Details','KYC Documents'],
    ROUTES : [
        '/register',
        '/individual-details',
        '/company-details',
        '/bank-details',
        '/kyc-documents',
        '/director-details'
      ],
   DASHBOARD_PROGRESS_FILTER : ['Last 7 Days', 'Last 30 Days', '6 Months', '12 Months'],
   DASHBOARD_AREA_FILTER:["Monthly", "Quarterly", "Yearly", "All Time"],
   ACCOUNT_TYPE :[{ value: 'Savings', label: "Savings" }, { value: 'Current', label: "Current" },{ value: 'CashorCredit', label: "CashorCredit" },{ value: 'Overdraft', label: "Overdraft" }],
   BUSINESS_ACCOUNT_TYPE :[{ value: 'Current', label: "Current" },{ value: 'Odcc', label: "ODCC" }]
}

