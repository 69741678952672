import { css } from "@emotion/react";
import { colors,typography } from "../../scss/colors";

export const containerStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${colors.charcoal};
  ${typography('600', '20px')};

  @media(max-width:768px){
  flex-direction: column;
  gap:10px;
  }
`;
export const directorSection = css`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  @media(max-width:768px){
  width:100%;
  justify-content:center;
  }
`;
export const AdddirectorSection = css`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  @media(max-width:768px){
  width:100%;
  justify-content:center;
  }
`;

export const NoteSection = css`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  color:${colors.charcoal};
  @media(max-width:768px){
  width:100%;
  margin-top:10px;
  }
`;
export const breakLine = css`
  border: 1px solid rgba(100, 130, 130, 0.25);
  margin-bottom: 25px;
`;
export const directorContainer = css`
  margin-bottom: 40px;
`;