import { css } from '@emotion/react';
import { colors, typography } from '../../../../scss/colors';

export const bankSaveStyle = css`
  margin-top: 22px;
  text-align: left;
  display: flex;
  align-items: center;
  @media (max-width:768px){
  margin-top:0px;
  }
`;


export const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
export const formControlStyle = css`
margin-bottom: 16px;
width: 100%;
`;
export const saveValidateButtonStyle = (props: { disabled: boolean }) => css`
  background-color: ${colors.white};
  color: ${colors.crimson};
  padding: 10px 20px;
  border: 1px solid ${colors.crimson};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props.disabled ? 0.5 : 1}; 
  ${typography('bold', '16px')};
   @media(max-width:768px){
width: 100%;
}
  &:disabled {
    background-color: white;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: ${colors.crimson};
    color: ${colors.white};
  }
`;

export const successMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

export const errorMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

export const messageContainerStyle = css`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;
export const registrationOtpContainer = css`
  padding:10px;
  @media (max-width:768px){
 padding:0px;
  }
`