import { css } from "@emotion/react";
import { colors } from "../../../scss/colors";


export const cardGridContainer = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    width: max-content;
  }
`;

export const CardContainer = css`
  width: 100%;
  display: flex;
  flex-grow: 1;
`;

export const chartsTablesGridContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr; 
  }
`;

export const chartsLeftSection = css`
  border: 1px solid ${colors.stroke};
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    border-radius: 10px;
  }
`;
export const noDataContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 20px;
  color: ${colors.lightBlack};
`;