import { css } from "@emotion/react";
import { colors, typography } from "../../../../scss/colors";

export const kycSubHeading = css`
color: ${colors.charcoal};
${typography('600', '16px')}
display:flex;
justify-content:flex-start;
margin-bottom:20px;
`

export const previewSection = css`
color: ${colors.buttonOrange};
${typography('700', '16px')}
text-decoration:line;
text-decoration: underline;
display: flex;
margin-bottom:20px;
cursor:pointer;
`
   export const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;

    export const MainGridContainerStyle = css`
width: calc(100% + 30px);
display:contents;
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
    export const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;