/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomStepper from '../../components/Stepper/Stepper';
import CustomButton from '../../components/Button/Button';
import Layout from '../../components/Layout/Layout';
import { utilsConstants } from '../../utils/utilsConstants';
import { useNavigate } from 'react-router-dom';
import { addMoreButton, breakLine, disableAddMoreButton, regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../Registration/Registration.style';
import { StringConstants } from '../../constants/Strings';
import Accordian from '../../components/Accordian/Accordian';
import { getDirectorGETApiUrl, getDirectorPostApiUrl } from '../../apiManger/apiConstants';
import { get, post } from '../../apiManger/apiMethods';
import { getLocalStorage, setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../components/Loader/Loder';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import { directorSection, AdddirectorSection, NoteSection, directorContainer,containerStyle } from './DirectorDetails.style';
import { errorMsg } from '../KycDocumentes/KycDocuments.style';


interface AccordionContent {
  firstName: string;
  lastName: string;
  mobileNo: string;
  emailId: string;
  gender: string;
  panNo: string;
  aadharNo: string;
  dateOfBirth: string;
  ownershipShare: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  landmark: string;
  postCode: string;
  city: string;
  state: string;
  id?: string;
}

interface Accordion {
  title: string;
  content: AccordionContent;
  isActive: boolean;
}

interface Errors {
  [key: string]: string | null;
}

const DirectorDetails: React.FC = () => {
  const { showToast } = useToastHook()
  const { BACK_LABEL, NEXT_LABEL, DIRECTOR_DETAILS, ADDMORE_BUTTON_LABEL } = StringConstants;
  const [stepCount, setStepCount] = useState<number>(2);
  const navigate = useNavigate();
  const { STEPPER_INDIVIDUAL_DIRECTOR } = utilsConstants;
  const registrationId = getLocalStorage('registrationId') as string;
  const [accordions, setAccordions] = useState<Accordion[]>([]);
  const [errors, setErrors] = useState<Errors>({});
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const entityType = getLocalStorage('Entity Type');
  const partnershipTypeLS = getLocalStorage('Partnership Type');
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  useEffect(() => {
    getAPICall();
  }, []);
  const getAPICall = async () => {
    showLoadingIndicator();
    const response = await get({ url: getDirectorGETApiUrl(registrationId) });
    if (response?.result) {
      hideLoadingIndicator()
      const fetchedAccordions = response?.result?.map((director: any, index: number) => ({
        title: director.firstName || "Add New",
        content: {
          firstName: director.firstName,
          lastName: director.lastName,
          mobileNo: director.mobile,
          emailId: director.emailId,
          gender: director.gender,
          panNo: director.pan,
          aadharNo: director.aadhar,
          dateOfBirth: director.dob,
          ownershipShare: director.ownershipPercentage,
          addressLine1: director.addressLineOne,
          addressLine2: director.addressLineTwo,
          addressLine3: director.addressLineThree,
          landmark: director.landmark,
          postCode: director.postalCode,
          city: director.city,
          state: director.state,
          id: director.id
        },
        isActive: index === 0
      }));

      setAccordions(fetchedAccordions);

    } else {
      hideLoadingIndicator();
    }
  };

  const handleNext = async () => {
    showLoadingIndicator();
    let reqObj = accordions.map((accordion) => ({
      registrationId: registrationId,
      firstName: accordion.content.firstName,
      lastName: accordion.content.lastName,
      mobile: accordion.content.mobileNo,
      emailId: accordion.content.emailId,
      gender: accordion.content.gender,
      pan: accordion.content.panNo,
      aadhar: accordion.content.aadharNo,
      dob: accordion.content.dateOfBirth,
      ownershipPercentage: accordion.content.ownershipShare,
      addressLineOne: accordion.content.addressLine1,
      addressLineTwo: accordion.content.addressLine2,
      addressLineThree: accordion.content.addressLine3,
      landmark: accordion.content.landmark,
      postalCode: accordion.content.postCode,
      city: accordion.content.city,
      state: accordion.content.state,
      status: 1,
      id: accordion.content.id 
    }));

    try {
      const response = await post({
        url: getDirectorPostApiUrl(registrationId),
        obj: reqObj
      });

      if (response?.result) {
        hideLoadingIndicator();

        setStepCount(3);
        navigate('/bank-details');
        let existingArray = JSON.parse(getLocalStorage("completed") || "[]");
        if (!existingArray.includes("director")) {
          existingArray.push("director");
        }
        setLocalStorage("completed", JSON.stringify(existingArray));

      } else if (response?.error?.message) {
        showToast(response?.error?.message, "error")
        hideLoadingIndicator();
      }
      else {
        showToast("something went wrong", "error")
        hideLoadingIndicator();
      }

    } catch (error) {
      showToast("something went wrong", "error")
      hideLoadingIndicator();
      console.error("Failed to submit individual details:", error);

    }
  }
  const handleBack = () => {
    if (entityType.includes("Individual")) {
      setStepCount(1);
      navigate("/bank-details");
    } else {
      setStepCount(1);
      navigate('/company-details')
    }
  };

  const handleToggle = (index: number) => {
    setAccordions((prev) =>
      prev.map((accordion, i) =>
        i === index
          ? { ...accordion, isActive: !accordion.isActive }
          : { ...accordion, isActive: false }
      )
    );
  };

  const handleDelete = async(index: number) => {
    const selectedAccordion = accordions[index];
    if (!selectedAccordion?.content?.id) {
      setAccordions((prev) => prev.filter((_, i) => i !== index));
    } else {
    const filterData = accordions.filter((_,i) => 
       i === index )
    showLoadingIndicator();
    let reqObj = filterData.map((accordion) => ({
      registrationId: registrationId,
      firstName: accordion.content.firstName,
      lastName: accordion.content.lastName,
      mobile: accordion.content.mobileNo,
      emailId: accordion.content.emailId,
      gender: accordion.content.gender,
      pan: accordion.content.panNo,
      aadhar: accordion.content.aadharNo,
      dob: accordion.content.dateOfBirth,
      ownershipPercentage: accordion.content.ownershipShare,
      addressLineOne: accordion.content.addressLine1,
      addressLineTwo: accordion.content.addressLine2,
      addressLineThree: accordion.content.addressLine3,
      landmark: accordion.content.landmark,
      postalCode: accordion.content.postCode,
      city: accordion.content.city,
      state: accordion.content.state,
      status: 0,
      id: accordion.content.id 
    }));

    try {
      const response = await post({
        url: getDirectorPostApiUrl(registrationId),
        obj: reqObj
      });

      if (response?.result) {
        hideLoadingIndicator();
        showToast("Deleted Succesfully", "success")
        getAPICall();
      } else if (response?.error?.message) {
        showToast(response?.error?.message, "error")
        hideLoadingIndicator();
      }
      else {
        showToast("something went wrong", "error")
        hideLoadingIndicator();
      }

    } catch (error) {
      showToast("something went wrong", "error")
      hideLoadingIndicator();
      console.error("Failed to submit individual details:", error);

    }
  }
  };

  const handleAddMore = () => {
    const newAccordion: Accordion = {
      title: "Add New",
      content: {
        firstName: "",
        lastName: "",
        mobileNo: "",
        emailId: "",
        gender: "",
        panNo: "",
        aadharNo: "",
        dateOfBirth: "",
        ownershipShare: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        landmark: "",
        postCode: "",
        city: "",
        state: ""
      },
      isActive: true
    };
    if (accordions?.length) {
      setAccordions(prevAccordions =>
        prevAccordions?.map(accordion => ({ ...accordion, isActive: false }))
          .concat(newAccordion)
      );
    } else {
      setAccordions([newAccordion]);
    }

  };

  const updateAccordionContent = (index: number, updatedContent: AccordionContent) => {
    setAccordions((prevAccordions) => {
      const newAccordions = prevAccordions?.map((accordion, i) => ({
        ...accordion,
        content: i === index ? { ...updatedContent, id: accordion.content.id } : accordion.content,
        isActive: i === index,
      }));

      newAccordions[index].title = updatedContent.firstName || "Add New";

      if (updatedContent.ownershipShare !== undefined) {
        newAccordions[index].content.ownershipShare = updatedContent.ownershipShare;

        const newTotalOwnershipShare = newAccordions.reduce((total, accordion) => {
          return total + Number(accordion.content.ownershipShare);
        }, 0);

        let validationError: any = null;
        if (newTotalOwnershipShare > 100) {
          validationError = "Total ownership share cannot exceed 100%";
        }

        setErrors((prevErrors) => ({
          ...prevErrors,
          ownershipShareValid: validationError,
        }));
      }

      return newAccordions;
    });
  };


  const totalOwnershipShare = accordions.reduce((total, accordion) => {
    return total + Number(accordion.content.ownershipShare);
  }, 0);

  const isAddMoreDisabled = totalOwnershipShare >= 100;


  useEffect(() => {
    const isContentValid = accordions?.length && accordions.every((accordion) =>
      Object.entries(accordion.content)
        .filter(([key]) => !['addressLine2', 'addressLine3', 'landmark'].includes(key))
        .every(([, value]) => value !== "")
    );
    const isFormValid = Object.values(errors).every((error) => error === null);
    if ((entityType?.includes("Business")) && ((partnershipTypeLS?.includes("Private") || (partnershipTypeLS?.includes("Public"))))) {
      if (isFormValid && isContentValid && (totalOwnershipShare >= 75 && totalOwnershipShare <= 100)) {
        setIsNextButtonDisabled(false)
      } else {
        setIsNextButtonDisabled(true)
      }
    } else if ((entityType?.includes("Business")) && partnershipTypeLS && (!(partnershipTypeLS?.includes("Private") && !(partnershipTypeLS?.includes("Public"))))) {
      if ((isFormValid && isContentValid) && totalOwnershipShare === 100) {
        setIsNextButtonDisabled(false)
      } else {
        setIsNextButtonDisabled(true)
      }
    } else {
      setIsNextButtonDisabled(true)
    }
  }, [errors, accordions, isNextButtonDisabled, totalOwnershipShare]);

  const mobile = window.innerWidth < 768;
const noteText  =  ((entityType?.includes("Business")) && ((partnershipTypeLS?.includes("Private") || (partnershipTypeLS?.includes("Public"))))) 
? "Total ownership share of directors should reach between 75% to 100%" :"Total ownership share of directors should reach to 100%"
  return (
    <Layout>
      {!mobile && <CustomStepper steps={STEPPER_INDIVIDUAL_DIRECTOR} stepCount={stepCount} />}
      <div>
        {visible ?
          <>{loadingComponent()}</> :
          <>   <div css={containerStyle}>
            <div css={directorSection}>
              {DIRECTOR_DETAILS}

            </div>
            
            <div css={AdddirectorSection}>
              <CustomButton
                label={ADDMORE_BUTTON_LABEL}
                onClick={handleAddMore}
                variant="outlined"
                css={(getLocalStorage("final") || isAddMoreDisabled) ? disableAddMoreButton : addMoreButton}
              />
            </div>
          </div>
          <div css={NoteSection}>{noteText}</div>
            <hr css={breakLine} />
            <div css={directorContainer}>
              {accordions?.length > 0 && accordions?.map((accordion, index) => (
                getLocalStorage("final") ? <Accordian
                  key={index}
                  title={accordion.title}
                  content={accordion.content}
                  isActive={accordion.isActive}
                  onClick={() => handleToggle(index)}
                  onUpdate={(updatedContent: AccordionContent) => updateAccordionContent(index, updatedContent)}
                  setErrors={setErrors}
                  errors={errors}
                /> : <Accordian
                  key={index}
                  title={accordion.title}
                  content={accordion.content}
                  isActive={accordion.isActive}
                  onClick={() => handleToggle(index)}
                  onDelete={() => handleDelete(index)}
                  onUpdate={(updatedContent: AccordionContent) => updateAccordionContent(index, updatedContent)}
                  setErrors={setErrors}
                  errors={errors}
                />
              ))}
            </div>
            {isAddMoreDisabled && (
              <div css={errorMsg}>
                Total ownership share has reached 100%
              </div>
            )}

            <div css={registrationButtonContainer}>
              <CustomButton
                label={BACK_LABEL}
                onClick={handleBack}
                variant="outlined"
                css={regBackButton}
                disabled={stepCount === 0}
              />
              <CustomButton
                label={NEXT_LABEL}
                onClick={handleNext}
                css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}
              />
            </div>
          </>}
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default DirectorDetails;
