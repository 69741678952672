/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import CustomButton from "../../../components/Button/Button";
import CustomInput from "../../../components/Input/Input";
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer,regBackButtonDisabled } from "../../Registration/Registration.style";
import { StringConstants } from "../../../constants/Strings";
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { get, post } from '../../../apiManger/apiMethods';
import { getSanction2GETApiUrl, getSanctionGETApiUrl, getSanctionPostApiUrl, getSanctionTwoPostApiUrl} from '../../../apiManger/apiConstants';
import Loder from "../../../components/Loader/Loder";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import { sanctionSection, SanctionLabel, sanctionApproved, commentSection, commentLabel, commentValue } from "./styles/Sanction.style";


const Sanction = (props: any) => {
    const navigate = useNavigate()
    const { showToast } = useToastHook()
    const { isSanction2Path,filterByStatus,setShowDetails } = props;
    const {  COMMENT, SANCTION_APPROVED,SCREEN_NAME:{UPLOAD_DOCUMENT,ESIGN,SANCTION_TWO,SANCTION_ONE} } = StringConstants;
    const [idval, setIdVal] = useState<any>("");
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const registrationId = getLocalStorage('Application_registrationId');
    const [santionData, setSanctionData] = useState({
        sanction1ApprovedBy: "",
        sanction1Comment: "",
        sanction1InputValue:"",
        sanction2ApprovedBy: "",
        sanction2Comment: "",
        sanction2InputValue:"",
        sanctionStatus:""
    })
    useEffect(() => {
        if (isSanction2Path) {
            getSaction2APICall()
        }
        getAPICall();
    }, [isSanction2Path])
    setLocalStorage('filterByStatus', filterByStatus)

    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getSanctionGETApiUrl(registrationId)
        });
        if (response?.result) {
            hideLoadingIndicator()
            setIdVal(response.result.id)
            setSanctionData((pre) => ({
                ...pre,
                sanction1Comment: response.result.comment,
                sanction1ApprovedBy: response.result.actionBy,
                sanctionStatus:response.result.sanctionStatus
            }))
        }
        else {
            hideLoadingIndicator()
            console.log('error')
        }
    }
    const getSaction2APICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getSanction2GETApiUrl(registrationId)
        });
        if (response?.result) {
            hideLoadingIndicator()
            setIdVal(response.result.id)
            setSanctionData((pre) => ({
                ...pre,
                sanction2Comment: response.result.comment,
                sanction2ApprovedBy: response.result.actionBy,
                sanctionStatus:response.result.sanctionStatus
            }))
        }
        else {
            hideLoadingIndicator()
            console.log('error')
        }
    }

    const handleApprove = async (approvalstatus: any) => {
        const {sanction1InputValue,sanction2InputValue} =  santionData;
        showLoadingIndicator();
       
        const payload1 :any= {
            registrationId,
                comment: sanction1InputValue,
                sanctionStatus: approvalstatus,
                status: 1
        }

        const payload2 :any= {
            registrationId,
                comment: sanction2InputValue,
                sanctionStatus: approvalstatus,
                status: 1
        }
        if(idval){
            payload1.id = idval
            payload2.id = idval
        }
        try {
            const response = await post({
                url: isSanction2Path ? getSanctionTwoPostApiUrl() : getSanctionPostApiUrl(),
                obj: isSanction2Path ?payload2 :payload1,
            });

            if (response?.result?.screenName) {
                showToast(approvalstatus === "Rejected" ? "Rejected successfully" : "Approved successfully", "success")
                if(isSanction2Path){
                    if(response?.result?.screenName===UPLOAD_DOCUMENT || response?.result?.screenName===ESIGN){
                        navigate("/e-sign")
                    }
                    else if(response?.result?.screenName===SANCTION_TWO){
                        setLocalStorage('filterByStatus','Rejected')
                        setShowDetails(false)
                        navigate("/sanction-2")
                    }
                    else{
                        showToast("Please retry after sometime", "error")
                    }
                }
                else{
                    if(response?.result?.screenName===SANCTION_TWO){
                        navigate("/sanction-2")
                    }
                    else if(response?.result?.screenName===SANCTION_ONE){
                        setLocalStorage('filterByStatus','Rejected')
                        setShowDetails(false)
                        navigate("/sanction")
                    }
                    else{
                        showToast("Please retry after sometime", "error")
                    }
                }
                hideLoadingIndicator();
            } else if (response?.error?.message) {
                showToast(response?.error?.message, "error")
                hideLoadingIndicator();
            }
        } catch (error) {
            showToast("something went wrong", "error")
            hideLoadingIndicator();
        }
    };
    return (
        <div>
            {visible ?
                <>{loadingComponent()}</> :
                <>
                    {isSanction2Path ? (
                        <>
                            <div css={sanctionSection}>
                                <div css={SanctionLabel}>{SANCTION_APPROVED}  <span css={sanctionApproved}> {santionData?.sanction1ApprovedBy}</span></div>
                                <div css={commentSection}>
                                    <div css={commentLabel}>{COMMENT} :</div>
                                    <div css={commentValue}>{santionData?.sanction1Comment}</div>
                                </div>
                            </div>
                            {santionData?.sanction2Comment ? (
                                <div css={sanctionSection}>
                                    <div css={SanctionLabel}>Sanction 2 {santionData?.sanctionStatus} By <span css={sanctionApproved}> {santionData?.sanction2ApprovedBy}</span></div>
                                    <div css={commentSection}>
                                        <div css={commentLabel}>{COMMENT} :</div>
                                        <div css={commentValue}>{santionData?.sanction2Comment}</div>
                                    </div>
                                </div>
                            ) :
                                <CustomInput
                                    inputLabel={isSanction2Path ? "Action" : "Comment"}
                                    value={santionData?.sanction2InputValue || santionData?.sanction2Comment  }
                                    onChange={(e) => 
                                        setSanctionData({
                                            ...santionData,
                                            sanction2InputValue: e.target.value,
                                        })
                                    }
                                    type="text"
                                    required
                                    placeholder="Enter your comment"
                                />
                            }
                        </>
                    ) :
                        <>
                        {santionData?.sanction1Comment && (
                            <div css={sanctionSection}>
                                <div css={SanctionLabel}>Sanction {santionData?.sanctionStatus} By  <span css={sanctionApproved}> {santionData?.sanction1ApprovedBy}</span></div>
                                <div css={commentSection}>
                                    <div css={commentLabel}>{COMMENT} :</div>
                                    <div css={commentValue}>{santionData?.sanction1Comment}</div>
                                </div>
                            </div>
                         )}
                            {!santionData?.sanction1Comment && (
                                <CustomInput
                                    inputLabel={isSanction2Path ? "Action" : "Comment"}
                                    value={santionData?.sanction1InputValue || santionData?.sanction1Comment  }
                                    onChange={(e) => {
                                        setSanctionData({
                                            ...santionData,
                                            sanction1InputValue: e.target.value,
                                        })
                                    }}
                                    type="text"
                                    required
                                    placeholder="Enter your comment"
                                />
                            )}
                        </>
                    }
                    <div css={registrationButtonContainer}>
                        <CustomButton
                            label="Reject"
                            variant="outlined"
                            css={filterByStatus?.toUpperCase()!==StringConstants.APPROVED_STATE ? regBackButton : regBackButtonDisabled}
                            onClick={() => handleApprove('Rejected')}

                        />
                        <CustomButton
                            label="Approve"
                            css={filterByStatus?.toUpperCase() === StringConstants.PENDING_STATE ? regNextButton : regNextButtonDisabled}
                            onClick={() => handleApprove('Approved')}
                        />
                    </div>
                </>}
            <ToastContainer />
        </div>
    );
};

export default Sanction;
