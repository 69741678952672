/** @jsxImportSource @emotion/react */
import { useState } from "react";
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import { InitiateKycDetailsSection, VeirfyMainHeadding, payoutMainSection } from "../ApplicationReview/InitiateKyc/styles/InitiateKyc.style";
import Profile from "./Profile";
import { StringConstants } from "../../constants/Strings";


const Account = () => {
  const [value, setValue] = useState(0);
  const { ACCOUNT } = StringConstants;
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const handleProfileUpdate = () => {
    setIsProfileUpdated(prevState => !prevState);
  };
  const tabs = [
    {
      label: 'Profile',
      content: (
        <div>
          <Profile onUpdate={handleProfileUpdate} />
        </div>
      ),
    },
  ];

  return (
    <div key={isProfileUpdated ? 'updated' : 'not-updated'}>
      <DashboardLoyout>
        <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>{ACCOUNT}</div>
        </div>
        <div css={InitiateKycDetailsSection}>
          <TabsComponent tabs={tabs} value={value} setValue={setValue} center={true} />
        </div>
      </DashboardLoyout>
    </div>
  )
}
export default Account;