/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import CustomInput from '../Input/Input';
import { css } from '@emotion/react';
import { StringConstants } from '../../constants/Strings';
import CustomSelect from '../Select/Select';
import { ValidName, dymanicWhiteSpaceValid, getEighteenYearsAgoDate, isMobileValid, maskAadhaar, removeAadhaarMask, validEmail, validateAadhaar, validateNumberRange, validatePAN, validatePostalCode, whiteSpaceValid } from '../../constants/Validations';
import { getPinCodeApiUrl } from '../../apiManger/apiConstants';
import { get } from '../../apiManger/apiMethods';
import { Box, Grid } from '@mui/material';
import { colors } from '../../scss/colors';
import { renderVerificationStatus } from '../RenderVerification/RenderVerification';

export const positionStyle = css`
position: relative
`
const AccordianContentForm = (props: any) => {
    const { content, onUpdate, setErrors, errors } = props;
    const { firstName, lastName, mobileNo, emailId, panNo, aadharNo, dateOfBirth, addressLine1, addressLine2, addressLine3, city, state, postCode, gender, ownershipShare, landmark, id } = content;
    const [aadhar, setAadhar] = useState(aadharNo ? aadharNo : "")
    const [individualDetails, setIndividialDetail] = useState<any>({
        firstName: firstName,
        lastName: lastName,
        mobileNo: mobileNo,
        emailId: emailId,
        gender: gender,
        panNo: panNo,
        aadharNo: aadharNo,
        dateOfBirth: dateOfBirth,
        ownershipShare: ownershipShare,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        landmark: landmark,
        postCode: postCode,
        city: city,
        state: state,
        id: id

    })
    const [panVerifiedStatus] = useState(content?.panValidationStatus === "VALID" ? true : content?.panValidationStatus === "INVALID" ? false : null)
    const [uidVerified] = useState(content?.uidValidationStatus === "VALID" ? true : content?.uidValidationStatus === "INVALID" ? false : null)
    const [emailIdVerified] = useState(content?.emailValidationStatus === "VALID" ? true : (content?.emailValidationStatus === "INVALID" || content?.emailValidationStatus === "NA") ? false : null)
    const [emailIdVerifiedStatus] = useState(content?.emailValidationStatus ? content?.emailValidationStatus : "")
    const { SELECT_PLACEHOLDER } = StringConstants;

    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'firstName':
            case 'lastName':
                return ValidName;
            case 'mobileNo':
                return isMobileValid;
            case 'emailId':
                return validEmail;
            case 'panNo':
                return validatePAN
            case 'aadharNo':
                return validateAadhaar
            case 'addressLine1':
            case 'addressLine2':
            case 'addressLine3':
            case 'landmark':
            case 'city':
                return whiteSpaceValid
            case 'postCode':
                return validatePostalCode
            case 'ownershipShare':
                return validateNumberRange
            default:
                return () => null;
        }
    };



    const handleChange = (fieldName: any, value: any) => {
        if (fieldName === 'aadharNo') {
            const unmaskedValue = removeAadhaarMask(value);
            setAadhar(unmaskedValue);

            setIndividialDetail({
                ...individualDetails,
                [fieldName]: unmaskedValue
            });
        }
        else {
            setIndividialDetail({
                ...individualDetails,
                [fieldName]: value
            });
        }

        const validatorFn = getValidator(fieldName);
        const error = validatorFn(value);

        setErrors({
            ...errors,
            [fieldName]: error
        });

    };

useEffect(() => {
    onUpdate(individualDetails);
}, [individualDetails]);


    useEffect(() => {
        if (individualDetails.postCode?.length === 6) {
            getLocation(individualDetails.postCode)
        }
    }, [individualDetails.postCode])
    const getLocation = async (postCode: any) => {
        const response = await get({
            url: getPinCodeApiUrl(postCode)
        });
        if (response?.result) {
            setIndividialDetail({
                ...individualDetails,
                state: response?.result?.state,
                city: response?.result?.city
            })
        }
    }


    const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;


    return (
        <div>
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                <Grid container spacing={2} css={gridContainerStyle}>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="First Name"
                            value={individualDetails.firstName}
                            onChange={(e) => handleChange('firstName', e.target.value)}
                            type="text"
                            css={formControlStyle}
                            required
                            placeholder='Enter First Name'
                            mandatory={true}
                            validate={ValidName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Last Name"
                            value={individualDetails.lastName}
                            onChange={(e) => handleChange('lastName', e.target.value)}
                            type="text"
                            css={formControlStyle}
                            required
                            placeholder='Enter Last Name'
                            mandatory={true}
                            validate={ValidName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Mobile No."
                            value={individualDetails.mobileNo}
                            type="text"
                            css={formControlStyle}
                            onChange={(e) => (e.target.value === '' || /^[6789]\d{0,9}$/.test(e.target.value)) ? handleChange("mobileNo", e.target.value) : null}
                            required
                            placeholder='Enter Mobile No.'
                            mandatory={true}
                            validate={isMobileValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} css={positionStyle}>
                        <CustomInput
                            inputLabel="Email ID"
                            value={individualDetails.emailId}
                            onChange={(e) => handleChange('emailId', e.target.value)}
                            type="text"
                            css={formControlStyle}
                            required
                            placeholder='Enter Email ID'
                            validate={validEmail}
                            mandatory={true}
                        />
                        {emailIdVerified != null && renderVerificationStatus(emailIdVerified, emailIdVerifiedStatus)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomSelect options={[{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }, { value: "Others", label: "Others" }]}
                            placeholder={SELECT_PLACEHOLDER}
                            value={individualDetails.gender}
                            onChange={(e) => handleChange('gender', e.target.value)}
                            inputLabel="Gender"
                            mandatory={true}
                            css={formControlStyle}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} css={positionStyle}>
                        <CustomInput
                            inputLabel="PAN"
                            value={individualDetails.panNo}
                            onChange={(e) => handleChange("panNo", e.target.value.toUpperCase())}
                            type="text"
                            css={formControlStyle}
                            required
                            placeholder='Enter PAN'
                            mandatory={true}
                            validate={validatePAN}
                        />
                        {panVerifiedStatus != null && renderVerificationStatus(panVerifiedStatus)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} css={positionStyle}>
                        <CustomInput
                            inputLabel="Aadhaar No"
                            value={maskAadhaar(aadhar)}
                            onChange={(e) => handleChange('aadharNo', e.target.value)}
                            type="text"
                            css={formControlStyle}
                            required
                            placeholder='Enter Aadhaar No'
                            mandatory={true}
                            validate={validateAadhaar}
                            max={12}
                        />
                        {uidVerified != null && renderVerificationStatus(uidVerified)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Date Of Birth"
                            value={individualDetails.dateOfBirth}
                            type="date"
                            css={formControlStyle}
                            onChange={(e) => handleChange('dateOfBirth', e.target.value)}
                            required
                            max={getEighteenYearsAgoDate()}
                            mandatory={true}
                            placeholder='Enter DOB'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Ownership Share(In %)"
                            value={individualDetails.ownershipShare}
                            onChange={(e) => handleChange('ownershipShare', e.target.value)}
                            placeholder='Enter Ownership share'
                            type="text"
                            css={formControlStyle}
                            required
                            mandatory={true}
                            validate={validateNumberRange}
                        />
                        {errors?.ownershipShareValid && (<div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors?.ownershipShareValid}</div>)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Address Line 1"
                            value={individualDetails.addressLine1}
                            onChange={(e) => handleChange('addressLine1', e.target.value)}
                            placeholder='Enter Address'
                            type="text"
                            css={formControlStyle}
                            required
                            mandatory={true}
                            validate={whiteSpaceValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Address Line 2"
                            value={individualDetails.addressLine2}
                            onChange={(e) => handleChange('addressLine2', e.target.value)}
                            placeholder='Enter Address'
                            type="text"
                            css={formControlStyle}
                            required
                            validate={whiteSpaceValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Address Line 3"
                            value={individualDetails.addressLine3}
                            onChange={(e) => handleChange('addressLine3', e.target.value)}
                            placeholder='Enter Address'
                            type="text"
                            css={formControlStyle}
                            required
                            validate={whiteSpaceValid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Landmark"
                            value={individualDetails.landmark}
                            onChange={(e) => handleChange('landmark', e.target.value)}
                            placeholder='Enter Address'
                            type="text"
                            css={formControlStyle}
                            required
                            validate={(value) => dymanicWhiteSpaceValid(value, "Invalid landmark")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="Postal Code"
                            value={individualDetails.postCode}
                            onChange={(e) => handleChange('postCode', e.target.value)}
                            placeholder='Enter Postal Code'
                            type="text"
                            css={formControlStyle}
                            required
                            mandatory={true}
                            validate={validatePostalCode}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            inputLabel="City / Town"
                            value={individualDetails.city}
                            onChange={(e) => handleChange('city', e.target.value)}
                            placeholder='Enter City/Town'
                            type="text"
                            css={formControlStyle}
                            required
                            mandatory={true}
                            validate={(value) => dymanicWhiteSpaceValid(value, "Invalid city")}
                            disabled

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <CustomInput
                            placeholder='Eanter state'
                            value={individualDetails.state}
                            onChange={(e) => handleChange('state', e.target.value)}
                            inputLabel="State"
                            mandatory={true}
                            css={formControlStyle}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* </div> */}

        </div>
    )
}


export default AccordianContentForm;