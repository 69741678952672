import { lazy } from 'react';
import Example from "../pages/Example/Example";
import DirectorDetails from '../pages/DirectorDetails/DirectorDetails';
import BankDetails from '../pages/BankDetails/BankDetails';
import KycDocumentes from '../pages/KycDocumentes/KycDocumentes';
import Dashboard from '../pages/Dashboard/Dashboard';
import UploadCpv from '../pages/UploadCpv/UploadCpv';
import LoginScreen from '../pages/LoginScreen/Login';
import Esign from '../pages/Esign/Esign';
import SignUp from '../pages/SignUp/SignUp';
import Overview from '../pages/Overview/Overview';
import InitiatePayout from '../pages/ApplicationReview/InitiatePayout/InitiatePayout';
import InitiateKyc from '../pages/ApplicationReview/InitiateKyc/InitiateKyc';
import InitiateCpv from '../pages/ApplicationReview/InitiateCpv/InitiateCpv';
import Sanction1 from '../pages/Sanction/Sanction1/Sanction1';
import Sanction2 from '../pages/Sanction/Sanction2/Sanction2';
import Account from '../pages/Account/Account';
import LoanApplication from '../pages/LoanApplication/LoanApplication';
import PayoutDetails from '../pages/PayoutDetails/PayoutDetails';
import Empanelment from '../pages/Empanelment/Empanelment';
const IndividualDetails = lazy(()=>import('../pages/IndividualDetails/IndividualDetails'));
const Registration = lazy(() => import('../pages/Registration/Registration'));
const CompanyDetails = lazy(()=>import('../pages/CompanyDetails/CompanyDetails'));



export const routeConfig = [
  {
    path: "/",
    component: <LoginScreen />,
  },
  {
    path: "/signup",
    component: <SignUp />,
  },
  {
    path: "/register",
    component: <Registration />,
  },
  {
    path:"/individual-details",
    component:<IndividualDetails/>
  },
  {
    path:"/company-details",
    component:<CompanyDetails/>
  },
  {
    path:"/bank-details",
    component:<BankDetails/>
  },
  {
    path: "/example-components",
    component: <Example />,
  },
  {
    path: "/director-details",
    component:<DirectorDetails />
  },
  {
    path: "/kyc-documents",
    component:<KycDocumentes />
  },
  {
    path: "/overview",
    component:<Overview />
  },
  {
    path: "/initiate-kyc",
    component:<InitiateKyc />
  },
  {
    path:"/initiate-kyc/initiate-cpv",
    component:<InitiateCpv />
  },
  {
    path:"/initiate-kyc/payout",
    component:<InitiatePayout />
  },
  {
    path:"/upload-cpv",
    component:<UploadCpv />
  },
  {
    path:"/sanction",
    component:<Sanction1 />
  },
  {
    path:"/sanction-2",
    component:<Sanction2 />
  },
  {
    path:"/e-sign",
    component:<Esign />
  },
  {
    path:"/empanelment",
    component:<Empanelment />
  },
  {
    path:"/account",
    component:<Account />
  },
  {
    path:"/loan-application",
    component:<LoanApplication />
  },
  {
    path:"/payout-details",
    component:<PayoutDetails />
  },
  {
    path:"/dashboard",
    component:<Dashboard />
  }
 
];

export const authArray = [
  "/individual-details",
  "/company-details",
  "/kyc-documents",
  "/director-details",
  "/bank-details",
 
 ];

 export const dashboardAuthArray = [
  "/dashboard",
  "/initiate-kyc",
  "/initiate-kyc/initiate-cpv",
  "/initiate-kyc/payout",
  "/sanction",
  "/sanction-2",
  "/e-sign",
  "/empanelment",
  "/loan-application"
 ]