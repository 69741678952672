/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';
import { icon } from '../../assets/Images';

const iconStyle = css`
  position: absolute;
  top: 58%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 10;

  @media (max-width: 768px) {
    right: 5px;
    top: 60%;
  }
`;

const textStyle = (verified: boolean) => css`
  color: ${verified ? colors.Green : "red"};
    ${typography("400", "14px")};
  text-align: left;
    margin-top: -10px;

  @media (max-width: 768px) {
    ${typography("500", "10px")};
  }
`;

export const renderVerificationStatus = (verified: boolean,status?:string) => {
  return (
    <>
      <span css={iconStyle}>
        {verified ? (
          <img src={icon.GreenCheckIcon} alt="Verified" />
        ) : (
          <img src={icon.ErrorIcon} alt="Not Verified" />
        )}
      </span>
      <div css={textStyle(verified)}>
        {status ? status : verified ? 'Verified' : 'Not Verified'}
      </div>
    </>
  );
};
