import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';

export const containerStyle = css`
color: ${colors.charcoal};
${typography('600', '20px')};
@media(max-width:768px){
${typography('600', '16px')};
}
`;

export const breakLine = css`
border:1px solid rgba(100, 130, 130, 0.25);
margin-bottom: 25px`


export const registrationOtpContainer = css`
display: flex;
align-items: flex-start;
flex-direction: column;
            `

export const regPanInput = css`
 width:225px ;
 height: 40px;

 @media (max-width:768px){
 width:345px;
 }
 `
export const registerPanInput = css`
 width:225px ;
 height: 40px;

 @media (max-width:768px){
 width:310px;
 }
 `

export const registrationPanInput = css`
 width:225px ;
 height: 40px;

 @media (max-width:768px){
 width:300px;
 margin-bottom:5px;
 }
 `
export const regiterTypeSection = css`
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
gap:30px;
`
export const regiterTypesSection = css`
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
gap:15px;
width:100%;
 
`

export const registrationButtonContainer = css`
widt:100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    @media (max-width: 768px) {
   display: flex;
   justify-content: center;
   align-items:center;
   gap: 20px;
    position: fixed;
    bottom: 0px; 
    left: 0;
    right: 0;
    border-top:1px solid ${colors.stroke};
    background-color: ${colors.white};
    z-index: 1;
    height:50px;
  }
    `
export const regBackButton = css`
        background-color:${colors.white};
        color: ${colors.buttonOrange};
        border-color: ${colors.buttonOrange};
        width:100px;
        height:40px;
        border-radius:4px;
        ${typography("700", "16px")};
        &:hover {
       background-color:${colors.white};
        color: ${colors.buttonOrange};
         border-color: ${colors.buttonOrange};
    }
    `
export const regBackButtonDisabled = css`
background-color:${colors.lightred};
color:${colors.white};
width:100px;
height:40px;
border-radius:4px;
${typography("700", "16px")};
pointer-events: none;
border:none;
box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgb(81 35 35 / 12%);
 &:hover {
        background-color:${colors.lightred}; 
       color:${colors.white};
    }
`
export const regNextButton = css`
        background-color:${colors.buttonOrange};
        color:${colors.white};
        width:100px;
        height:40px;
        border-radius:4px;
        ${typography("700", "16px")};
        padding:0px;
        &:hover {
        background-color:${colors.buttonOrange}; 
       color:${colors.white};
    }
`
export const regNextButtonDisabled = css`
background-color:${colors.lightred};
color:${colors.white};
width:100px;
height:40px;
border-radius:4px;
${typography("700", "16px")};
pointer-events: none;

 &:hover {
        background-color:${colors.lightred}; 
       color:${colors.white};
    }
`

export const addMoreButton = css`
        background-color:${colors.white};
        color: ${colors.benchmark};
        border:1px solid ${colors.benchmark};
        width:197px;
        height:40px;
        border-radius:4px;
        padding:0px;
        ${typography("700", "16px")};
        
    @media(max-width:768px){
      width:290px;
    }
        &:hover {
       background-color:${colors.white};
        color: ${colors.benchmark};
         border-color: ${colors.benchmark};

   
    }`

export const disableAddMoreButton = css`
        background-color:${colors.dropdownToggel};
        color: ${colors.white};
         border-color: ${colors.white};
      
        width:197px;
        height:40px;
        border-radius:4px;
        padding:0px;
        ${typography("700", "16px")};
        pointer-events: none;
    @media(max-width:768px){
      width:290px;
    }
        &:hover {
       background-color:${colors.white};
        color: ${colors.benchmark};
         border-color: ${colors.benchmark};

   
    }`