import { css } from '@emotion/react';
import { colors, typography } from '../../../../scss/colors';


export const gridContainerStyle = css`
width: calc(100% + 30px);
@media (max-width: 768px) {
    width: calc(100% + 20px);
}
`;
export const containerStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    color: ${colors.charcoal};
    ${typography('600', '18px')};
     @media(max-width:768px){
flex-direction: column;
gap:10px;
}
  `;
export const directorSection = css`
width: 100%;
display: flex;
justify-content: flex-start;
padding-top:30px;
padding-left:16px;
@media(max-width:768px){
width:100%;
justify-content:center;
}
`;
export const formControlStyle = css`
margin-bottom: 16px;
width: 100%; 
`;
