import { css } from "@emotion/react";
import { colors, typographyInter } from "../../../scss/colors";

export const marginBottom = css`
  margin-bottom: 20px;
`;
export const ChartsContainer = css`
display:flex;
width:100%;
justify-content:space-between;
 align-items:center;
gap:20px;
margin-top:20px;
@media (max-width: 768px) {
flex-direction:column;
align-items:unset;

}
`


export const AreaSection = css`
width:65%;
border:1px solid ${colors.stroke};
border-radius:5px;
padding:10px;
height:328px;
@media (max-width: 768px) {
width:100%;
padding:0px;
}
`
export const ProgressSection = css`
width:35%;
border:1px solid ${colors.stroke};
border-radius:5px;
padding:10px;
height:328px
`
export const headerSectionArea = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;
export const FilterSectionArea = css`
display:flex;
align-items:center;
justify-content:space-between;
gap:10px;
@media (max-width: 768px) {
    position: fixed;
    bottom: 24px; 
    left: 0;
    right: 0;
    background-color: ${colors.darkBlack};
    z-index: 1;
    border-radius:5px;
    gap:35px;
    height:30px;
  }
`
export const headdingDashbaord = css`
color: ${colors.lightBlack};
${typographyInter('700', '24px')};
text-align:left;
  @media (max-width: 768px) {
    display:none;
  }
`
export const headding = css`
  color: ${colors.lightBlack};
${typographyInter('700', '16px')};
text-align:left;
@media (max-width: 768px) {
display:none;
}
`

export const Mobileheadding = css`
display:none;
@media (max-width: 768px) {
display:flex;
color: ${colors.lightBlack};
${typographyInter('700', '16px')};
text-align:left;
width:100%;
margin-top:10px;
}
`
export const selectAreaOption = css`
 color: ${colors.placeholder};
${typographyInter('700', '12px')};
height:30px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
padding:0px 10px;
@media (max-width: 768px) {
color:${colors.placeholder};
width:20%;
padding:0px;
}
`
export const selectedDateFilter = css`
 color: ${colors.lightBlack};
border: 1px solid ${colors.stroke};
${typographyInter('700', '12px')};
height:30px;
border-radius:5px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
padding:0px 10px;
@media (max-width: 768px) {
color:${colors.filterLabel};
width:20%;
padding:0px;
}
`
