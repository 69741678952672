import { getDashboardSummaryGETApiUrl } from "../../apiManger/apiConstants";
import { post } from "../../apiManger/apiMethods";
import { utilsConstants } from "../../utils/utilsConstants";
import { getLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";

const getDateRange = (filter: string) => {
    const now = new Date();
    let start: Date;

    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    switch (filter) {
        case 'Monthly':
            start = new Date(currentYear, currentMonth, 1);
            break;

            case 'Quarterly':
                if (currentMonth >= 3 && currentMonth <= 5) { 
                    start = new Date(currentYear, 3, 1);
                } else if (currentMonth >= 6 && currentMonth <= 8) {
                    start = new Date(currentYear, 6, 1);
                } else if (currentMonth >= 9 && currentMonth <= 11) {
                    start = new Date(currentYear, 9, 1); 
                } else { 
                    start = new Date(currentYear, 0, 1);
                }
                break;
        case 'Yearly':
            if (currentMonth >= 3) { 
                start = new Date(currentYear, 3, 1); 
            } else {
                start = new Date(currentYear - 1, 3, 1);
            }
            break;
        default:
            start = new Date(currentYear, currentMonth, 1);
            break;
    }

    return { start, now };
};


const formatDateString = (date: Date) => {
    return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
};
export const getRenderData = async (filter: string = 'Monthly') => {
    const { start, now } = getDateRange(filter);
    const startDate = formatDateString(start)
    const endDate = formatDateString(now);
    const { DASHBOARD_AREA_FILTER } = utilsConstants;
    const dashboardId=getLocalStorage('dashboardId')
    let payload: any;
    if (filter === DASHBOARD_AREA_FILTER[3]) {
        payload = {
            "dashboard_id": dashboardId,
        }
    }
    else {
        payload = {
            "dashboard_id": dashboardId,
            "filters": [
                {
                    "filter_name": "la.created_at",
                    "values": [startDate, endDate]
                }
            ]
        }
    }
    try {
        const response = await post({
            url: getDashboardSummaryGETApiUrl(),
            obj: payload
        });
        return Promise.resolve(response.result?.dashboard_data);
    } catch (error) {
        console.error("Error fetching dashboard summary:", error);
        return Promise.resolve([]);
    }


}

