/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from '@emotion/react';
import {  SideBarData } from "./SideBarData";
import SideMenu from "./SideMenu";
import { colors, typography, typographyInter } from '../../scss/colors';
import { icon } from "../../assets/Images";
import { getLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CustomButton from "../Button/Button";

const SidebarWrapper = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 240px;
  background-color: ${colors.lightBlue};
  overflow-y: auto;
  display: flex;
  flex-direction: column; 
  border-right: 1px solid rgba(100, 130, 130, 0.25);
  @media (max-width: 650px) {
    display:none;
  }
`;

const SidebarLogoWrapper = css`
    text-align: left;
    padding: 16px 0px 30px 15px;
`;

const SidebarSection = css`
  padding: 16px;
  flex: 1; /* Allows the section to grow and push the logout to the bottom */
`;

const LogoutWrapper = css`
  padding: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid rgba(100, 130, 130, 0.25);
  margin-top: auto; /* Pushes the logout button to the bottom */
`;

const HeadingWrapper = css`
  text-align: center;
  margin-left: 10px;
  color: ${colors.lightBlack};
  ${typographyInter("400", "14px")}
`;

const modalMain = css`
  padding: 0px !important;
  .logout-model {
    padding: 0px 0px 40px 0px;
    text-align: center;
    .logout-model-header {
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: ${colors.crimson};
      margin-bottom: 45px;
    }
  }
`;

const modalBoxStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${colors.white};
  box-shadow: 24px;
  border-radius: 5px;
  padding: 30px;
  max-height: 90vh;
  overflow-y: auto;
  text-align: center;
`;

const regBackButton = css`
  background-color: ${colors.white};
  color: ${colors.buttonOrange};
  border-color: ${colors.buttonOrange};
  width: 100px;
  height: 40px;
  border-radius: 4px;
  ${typography("700", "16px")};
  &:hover {
    background-color: ${colors.white};
    color: ${colors.buttonOrange};
    border-color: ${colors.buttonOrange};
  }
`;

const regNextButton = css`
  background-color: ${colors.buttonOrange};
  color: ${colors.white};
  width: 100px;
  height: 40px;
  border-radius: 4px;
  ${typography("700", "16px")};
  padding: 0px;
  &:hover {
    background-color: ${colors.buttonOrange}; 
    color: ${colors.white};
  }
`;

const buttonSection = css`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const SideBar = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { ashvLogo, logout_icon } = icon;
  const {overviewInactive,overviewActive,veirfyInactive,verifyActive,sactionActive,
    sactionInactive,esignActive,esignInactive,employeeInactive,employeeActive,LoanInactive,LoanActive} = icon;
  const addSanctionItems = (sidebarMenu:any, roles:any) => {
    const sanctionItems :any= [];
    if (roles.includes("sanction_p1") || roles.includes("partner-Sanction 1")) {
      sanctionItems.push({
        title: "Sanction 1",
        path: "/sanction",
        icon: "",
      });
    }
    if (roles.includes("sanction_p2") || roles.includes("partner-Sanction 2")) {
      sanctionItems.push({
        title: "Sanction 2",
        path: "/sanction-2",
        icon: "",
      });
    }
    if (sanctionItems.length > 0) {
      sidebarMenu.push({
        title: "Sanction",
        path: "/sanction",
        icon: sactionInactive,
        active_icon: sactionActive,
        subMenus: sanctionItems,
      });
    }
  };
  
  const buildSidebarMenu = (roles:any) => {
    if (roles?.includes("sales")) {
      return SideBarData;
    }
    const sidebarMenu:any = [];
    sidebarMenu.push({
      title: "Overview",
      path: "/dashboard",
      icon: overviewInactive,
      active_icon: overviewActive,
    });
  
   
    if (roles?.includes("ops_role1")) {
      sidebarMenu.push({
        title: "Application Review",
        path: "/initiate-kyc",
        icon: veirfyInactive,
        active_icon: verifyActive,
        subMenus: [
          {
            title: "Initiate KYC",
            path: "/initiate-kyc",
            icon: "",
          },
          {
            title: "Initiate CPV/FCU",
            path: "/initiate-kyc/initiate-cpv",
            icon: "",
          },
          {
            title: "Payout Structure",
            path: "/initiate-kyc/payout",
            icon: "",
          }
        ],
      }, 
    );
    }
    addSanctionItems(sidebarMenu, roles);
    if (roles?.includes("ops_role1")) {
      sidebarMenu.push( {
        title: "E-Sign",
        path: "/e-sign",
        icon: esignInactive,
        active_icon: esignActive,
      });
    }
    if (Array.isArray(roles) && (roles.includes("customer") || roles.some((ele: string) => ele.includes("partner")))) {
      sidebarMenu.push({
        title: "Loan Applications",
        path: "/loan-application",
        icon: LoanInactive,
        active_icon: LoanActive,
      });
    }
    if (roles?.includes("legal")) {
      sidebarMenu.push({
        title: "Empanelment",
        path: "/empanelment",
        icon: employeeInactive,
        active_icon: employeeActive,
      });
    }
    

  
    return sidebarMenu;
  };
  
  const roles = getLocalStorage("user_groups") || [];
  const sidebarMenu = buildSidebarMenu(roles);
  
  const handleLogout = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const logoutApi = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <>
      <div css={SidebarWrapper}>
        <div css={SidebarLogoWrapper}>
          <img src={ashvLogo} alt="no_side_icon" />
        </div>
        <div css={SidebarSection}>
          {sidebarMenu.map((item: any, index: any) => (
            <div key={index}>
              <SideMenu item={item} index={index} />
            </div>
          ))}
        </div>
        <div css={LogoutWrapper} onClick={handleLogout}>
          <img src={logout_icon} alt="logout_icon" />
          <span css={HeadingWrapper}>Logout</span>
        </div>
      </div>
      <Modal open={showModal} onClose={handleModalClose}>
        <Box css={modalBoxStyle}>
          <div css={modalMain}>
            <p className="logout-model-header">
              Are you sure you want to logout?
            </p>
            <div css={buttonSection}>
              <CustomButton
                label="Cancel"
                onClick={handleModalClose}
                variant="outlined"
                css={regBackButton}
              />
              <CustomButton
                label="Logout"
                onClick={logoutApi}
                css={regNextButton}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SideBar;
