import { getLocalStorage } from "../utils/browserHandlers/browserStorageHandler";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REGISTER_API_URL=`${BASE_URL}/registration/create`

//Individual Details 
export const getIndividualPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/individual-details/add`;
};

export const getIndividualGETApiUrl = (regid:any,individual_id:any) => {
    return `${BASE_URL}/registration/${regid}/individual-details/${individual_id}`;
};

export const getIndividualPUTApiUrl = (regid:any,individual_id:any) => {
    return `${BASE_URL}/registration/${regid}/individual-details/${individual_id}/update`;
};

//Bank Details
export const getBankPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/bank-details/add`;
};
export const getBankGETApiUrl = (regid:any,bank_id:any) => {
    return `${BASE_URL}/registration/${regid}/bank-details/${bank_id}`;
};
export const getBankPUTApiUrl = (regid:any,bank_id:any) => {
    return `${BASE_URL}/registration/${regid}/bank-details/${bank_id}/update`;
};

export const getBankVerifyPOSTApiUrl = () => {
  return `${BASE_URL}/registration/bank-details/verify`;
};

//Company Details
export const getCompanyPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/company-details/add`;
};


export const getCompanyGETApiUrl = (regid:any,company_id:any) => {
    return `${BASE_URL}/registration/${regid}/company-details/${company_id}`;
};
export const getCompanyPUTApiUrl = (regid:any,company_id:any) => {
    return `${BASE_URL}/registration/${regid}/company-details/${company_id}/update`;
};


//Director Details
export const getDirectorPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/director-details/add`;
};
export const getDirectorGETApiUrl = (regid:any) => {
    return `${BASE_URL}/registration/${regid}/director-details/get`;
};
export const getDirectorPUTApiUrl = (regid:any,director_id:any) => {
    return `${BASE_URL}/registration/${regid}/company-details/${director_id}/update`;
};

  //Payout Docs Upload File
export const getPayoutDocsPostApiUrl = async (data: any) => {
    try {
    return fetch(`${BASE_URL}/registration/payout-document/update`, {
      method: "POST",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      });
    } catch (err: any) {
      return err.response
    }
  };


  //Partner KYC
  export const getPartnerKYCPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/partner-kyc/add`;
};

export const getPartnerKYCGETApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/partner-kyc/get`;
};

  //Company KYC
  export const getCompanyKYCPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/company-kyc/add`;
};

export const getCompanyKYCGETApiUrl = (id:any) => {
    return `${BASE_URL}/registration/${id}/company-kyc/get`;
};


//Get Initiate KYC list
  export const getApplicationListPOSTApiUrl = () => {
    return `${BASE_URL}/registration/application-list`;
};

export const getApplicationDetailsGETApiUrl = (regId:any) => {
    return `${BASE_URL}/registration/registration-mapper/${regId}`;
};

export const getStateGETApiUrl = () => {
    return `${BASE_URL}/v1/state/list`;
}
//POST Upload Payout docs
  export const getUploadPayoutDocsPostApiUrl = (id:any) => {
    return `${BASE_URL}/registration/payout-details/add`;
};

//GET Upload Payout docs
export const getUploadPayoutDocsGETApiUrl = (id:any) => {
    return `${BASE_URL}/registration/payout-details/${id}`;
};

//POST Payout slab
export const getUploadPayoutSlabPostApiUrl = () => {
    return `${BASE_URL}/registration/payout-slab/submit`;
};
//Get PAyout slab
export const getPayoutSlabGETApiUrl = (id:any) => {
  return `${BASE_URL}/registration/payout-slab/${id}/get`;
};


//POST Sanction
export const getSanctionPostApiUrl = () => {
    return `${BASE_URL}/sanction/add`;
};

export const getSanctionTwoPostApiUrl = () => {
  return `${BASE_URL}/sanction/two/add`;
};

export const getSanctionGETApiUrl = (id:any) => {
    return `${BASE_URL}/sanction/${id}/get`;
};

//GET Santion 2 Api 
export const getSanction2GETApiUrl = (id:any) => {
  return `${BASE_URL}/sanction/two/${id}/get`;
};


//GET Dashboard data
export const getDashboardGETApiUrl = () => {
    return `${BASE_URL}/dashboard/summary`;
};

//GET Dashboard data
export const getApplicationVsDisbursedGETApiUrl = () => {
    return `${BASE_URL}/dashboard/application-amount-vs-disbursed`;
};

//GET Dashboard data
export const getApplicationCountVsDisbursedCountGETApiUrl = () => {
    return `${BASE_URL}/dashboard/application-count-vs-disbursed`;
};

//GET Dashboard data
export const getProductVsApplicationGETApiUrl = () => {
    return `${BASE_URL}/dashboard/product-vs-applications`;
};

//GET Dashboard data
export const getProductVsDisbursedGETApiUrl = () => {
    return `${BASE_URL}/dashboard/product-vs-disbursed`;
};

//Profile data
export const getProfileGETApiUrl = () => {
    return `${BASE_URL}/registration/profile`;
};

export const getProfilePOSTApiUrl = () => {
    return `${BASE_URL}/registration/profile/update`;
};

export const generateOtpApiUrl = () => {
  return `${BASE_URL}/login/otp/generate`;
};

export const verifyOtpApiUrl = () => {
  return `${BASE_URL}/login/otp/verify`;
};

export const getDocumentApiUrl = (id:any) => {
  return `${BASE_URL}/document/download-documents/${id}`;
};


  //Download Doc
  export const getDownloadDocumentApi = async (id: any) => {
    const token = getLocalStorage("access_token");
      
    if (!token) {
      console.error("Authorization token is missing.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
    return fetch(`${BASE_URL}/document/download-documents/${id}`, {
      method: "GET",
      headers:headers
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        return data;
      });
    } catch (err: any) {
      return err.response
    }
  };
export const getContituteGETApiUrl = () => {
  return `${BASE_URL}/v1/constitution-type/list`;
}

export const getIndustryGETApiUrl = () => {
  return `${BASE_URL}/v1/industry-type/list`;
}

export const getSubIndustryGETApiUrl = (id:any) => {
  return `${BASE_URL}/v1/subindustry-type/list/${id}`;
}

export const uploadFileApiUrl = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('access_token');

    const headers = new Headers();
    if (accessToken) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    }
  return fetch(`${BASE_URL}/document/upload`, {
    method: "POST",
    body: data,
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
  } catch (err: any) {
    return err.response
  }
};

export const signInApiUrl = () => {
  return `${BASE_URL}/login/email/otp/generate`;
};

export const verifySignInApiUrl = () => {
  return `${BASE_URL}/login/email/otp/verify`;
};


export const empanelmentPOSTApiUrl = () => {
  return `${BASE_URL}/sanction/empanelment/add`;
};

export const getIDVerificationGETApiUrl = (id:any) => {
  return `${BASE_URL}/registration/get/identities/${id}`;
};

export const getCIBILGETApiUrl = (id:any) => {
  return `${BASE_URL}/registration/get/cibil/${id}`;
};

//Esign APIs
export const getEsignPostApiUrl = (id:any) => {
  return `${BASE_URL}/registration/${id}/e-sign/document/add`;
};

export const getEsignGETApiUrl = (id:any) => {
  return `${BASE_URL}/registration/${id}/e-sign/document/list`;
};

//dashboard api
export const getDashboardSummaryGETApiUrl:any = () => {
  return `${BASE_URL}/v2/dashboard/summary`;
}
export const getPinCodeApiUrl = (pincode:any) => {
  return `${BASE_URL}/registration/get/pin/${pincode}`;
};