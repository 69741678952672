export const isMobileValid = (mobile: any) => {
  const mobileStr = String(mobile);

  const mobile_regex = /^(?:\+91|91|0)?[6789]\d{9}$/;

  if (!mobile_regex.test(mobileStr)) {
    return 'Invalid mobile number';
  }

  const zeros_regex = /^[0]+$/;
  if (zeros_regex.test(mobileStr)) {
    return 'Mobile number cannot contain only zeros';
  }

  const strippedMobile = mobileStr.replace(/^(?:\+91|91|0)/, '');
  if (strippedMobile.startsWith('0')) {
    return 'Mobile number cannot start with zero';
  }

  return null;
};

  export const validatePAN = (panVal: any) => {
    const regpan = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regpan.test(String(panVal)) ? null : 'Invalid pan';
  };

  export const validateGst = (panVal: any) => {
    const regpan = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/;
    return regpan.test(String(panVal)) ? null : 'Invalid gst';
  };
  export const validEmail = (panVal: any) => {
    const regpan = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regpan.test(String(panVal)) ? null : 'Invalid email';
  };
  export const dymanicWhiteSpaceValid = (value: string, errorMessage: string) => {
    const spac_reg = /^[^\s].*/;
    return spac_reg.test(value) ? null : errorMessage;
  };
  
  export const whiteSpaceValid = (value: string) => {
    const spac_reg = /^[^\s].*/;
    return spac_reg.test(value) ? null : 'Invalid address';
  };


  export const validateAadhaar = (aadhaarVal: any) => {
    const regAadhaar = /^(\d{12}|x{8}\d{4})$/;
    return regAadhaar.test(String(aadhaarVal)) ? null : 'Invalid aadhar';
};

export const validatePostalCode = (postalCodeVal: any) => {
  const postalCodeRegex = /^[1-9][0-9]{5}$/;
  if (!postalCodeRegex.test(postalCodeVal)) {
      return 'Invalid postal code';
  }
  return null;
 
};

export const ValidName = (name: any) => {
  const nameRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/;
  return nameRegex.test(String(name)) ? null : 'Invalid name';
}

export const maskAadhaar = (aadhaarNo:any) => {
  if (aadhaarNo?.length === 12) {
      return aadhaarNo.slice(0, -4).replace(/\d/g, 'x') + aadhaarNo.slice(-4);
  }
  return aadhaarNo
};
export const removeAadhaarMask = (maskedAadhar:any) => maskedAadhar.replace(/\D/g, '');

export const getMinDate = () => {
  const today = new Date();
  const minDate = new Date(today.setFullYear(today.getFullYear() - 18));
  return minDate.toISOString().split('T')[0];
};

export const getEighteenYearsAgoDate = () => {
  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return eighteenYearsAgo.toISOString().split('T')[0]; 
};
export const getTodaysDate = () => {
  const today = new Date();
 
  return today.toISOString().split('T')[0]; 
};

export const ifscCodeValidation = (ifscCode:any) => {
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; 
  if (!ifscRegex.test(ifscCode)) {
    return "Invalid ifsc code";
  }
  return null; 
};

export const validateNumberRange = (inputValue: any) => {
  const numberRangeRegex = /^(?:100|[1-9]?[0-9])$/;

  if (!numberRangeRegex.test(inputValue)) {
    return "Please enter a number between 0 and 100";
  }
  return null;
};

export const validAccountNumber = (number: any) => {
  const accountno_regex = /^[0-9]{9,18}$/;
  if (!accountno_regex.test(number)) {
    return "Invalid account no";
  }
  return null;
};

