import React from 'react';
import {
  Tabs,
  Tab,
  Box,
  useTheme,
} from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: {
              xs: "30px 20px", 
              sm: "20px 10px 10px 15px", 
            },
            borderRadius: '0 0 4px 4px',
            marginTop: '-1px',
          }}
        >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabsComponentProps {
  tabs: Array<{ label: string; content: React.ReactNode }>;
 value:number;
 setValue:any;
 center?: boolean;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabs,value,setValue,center=false }) => {
  const theme = useTheme(); 
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        sx={{
          '& .MuiTabs-flexContainer': {
             overflowX: 'auto',
          width: '100%',
          borderBottom: '1px solid #ddd',
          ...(center && {
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'center',
            }
          })
          },
          '& .MuiTabs-scroller': {
            overflowX: 'auto',
            
            height: {
              md: '50px',   
            },
          },
          '& .MuiTab-root': {
         
            borderBottomColor: '#ddd', 
            width: "fit-content",
            color:'#6E7C87',
            fontSize:'14px',
            fontWeight:'500',
            fontFamily:'Inter',
            padding: '8px 10px',
            textTransform: 'none',
            [theme.breakpoints.down('sm')]: {
              paddingLeft:'10px'
            }
          },
          '& .MuiTab-root.Mui-selected': {
            borderBottomColor: 'transparent',
            borderBottom:  '1px solid #464646',
            color:'#464646',
            fontSize:'14px',
            fontWeight:'700',
            fontFamily:'Inter',
            [theme.breakpoints.down('sm')]: {
              fontSize: '14px',
              borderBottomColor: 'transparent',
              borderBottom: '1px solid #E12C36', 
              color:'#E12C36',
              fontWeight:'600',
              fontFamily:'Inter',
            },
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            label={tab.label}
            {...a11yProps(index)}
            key={index}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsComponent;
