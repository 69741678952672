/** @jsxImportSource @emotion/react */
import { useState, startTransition, useEffect, useMemo } from 'react';
import CustomStepper from '../../components/Stepper/Stepper';
import CustomButton from '../../components/Button/Button';
import { colors } from '../../scss/colors';
import Layout from '../../components/Layout/Layout';
import { utilsConstants } from '../../utils/utilsConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../Registration/Registration.style';
import { StringConstants } from '../../constants/Strings';
import InputFile from '../../components/InputFile/InputFile';
import { icon } from '../../assets/Images';
import { get, post } from '../../apiManger/apiMethods';
import { getCompanyKYCGETApiUrl, getCompanyKYCPostApiUrl, getDirectorGETApiUrl, getIndividualGETApiUrl, getPartnerKYCGETApiUrl, getPartnerKYCPostApiUrl, uploadFileApiUrl } from '../../apiManger/apiConstants';
import { getLocalStorage, setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../components/Loader/Loder';
import { Box, Grid } from '@mui/material';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from './Preview';
import { breakLine, containerStyle, formControlStyle, gridContainerStyle, gridContainerStyle2, kycSubHeading, submitGetBackText } from './KycDocuments.style';

const KycDocumentes = () => {
  const { showToast } = useToastHook()
  const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, RegisterTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
  const { SuccessGiff } = icon;
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const { BACK_LABEL, SUBMIT_LABEL, KYC_DOCUMENTS, KYC_VERIFICATION_HEADING, KYC_VERIFICATION_COMPANY_HEADING, SUBMITTED_SUCCESS_HEADING ,SCREEN_NAME:{VERIFY_KYC}} = StringConstants;
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any>({
    pan: { fileId: "", fileName: "" },
    aadhar: { fileId: "", fileName: "" },
    voter: { fileId: "", fileName: "" },
    driving: { fileId: "", fileName: "" },
    passport: { fileId: "", fileName: "" },
    additional1: { fileId: "", fileName: "" },
    additional2: { fileId: "", fileName: "" },
    business: { fileId: "", fileName: "" },
    gst: { fileId: "", fileName: "" },
    udyam: { fileId: "", fileName: "" },
    additional3: { fileId: "", fileName: "" },
    additional4: { fileId: "", fileName: "" }
  });

  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const entityType = getLocalStorage('Entity Type');
  const partnershipTypeLS = getLocalStorage('Partnership Type');

  const docList =useMemo(() => [
    { inputLabel: "PAN Card", name: "pan", docid: '1ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' ,mandatory:true},
    { inputLabel: "Aadhaar Card", name: "aadhar", docid: '2ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' ,mandatory:true},
    { inputLabel: "Voter ID", name: "voter", docid: '3ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: "Driving License", name: "driving", docid: "4ce76767-3999-4ceb-af72-a7ba96ac8f2d", value: '', fileId: ''}
  ],[]);
  const docList2 =useMemo(() => [
    { inputLabel: "Passport", name: "passport", docid: '5ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: entityType === 'Individual'?"Consumer bureau consent":"Commercial bureau consent", name: "bureau", docid: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '', fileId: '',mandatory:true},
    { inputLabel: "Additional Document", name: "additional1", docid: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '', fileId: '' },
    { inputLabel: "Additional Document", name: "additional2", docid: '10662581-4f7b-4c0d-918c-d937e1c9cf51', value: '', fileId: '' }
  ],[]);

  const companydocList = [
    { inputLabel: "Business PAN Card", name: "business", docid: "c98bf386-ec7a-43f7-a7b3-bed71ec28ff8", value: '', fileId: '',mandatory:(entityType === 'Individual' || partnershipTypeLS?.includes("Sole")) ? false:true  },
    { inputLabel: "GST", name: "gst", docid: 'fd542557-09bf-4568-b20a-3d0bedc2c0d8', value: '', fileId: '' ,mandatory:entityType?.includes('Business') ? true:false },
    { inputLabel: "Udyam Aadhaar", name: "udyam", docid: '15032b4c-5f45-47f7-8ec0-f1479af27a69', value: '', fileId: '' },
    { inputLabel: "Partnership Deed", name: "deed", docid: '11b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Partnership' || partnershipTypeLS === 'LLP')? true:false },
    { inputLabel: "MoA", name: "moa", docid: '12b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Private Ltd' || partnershipTypeLS === 'Public Ltd')? true:false },
    { inputLabel: "AoA", name: "aoa", docid: '13b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(partnershipTypeLS === 'Private Ltd' || partnershipTypeLS === 'Public Ltd')? true:false },
    { inputLabel: "Board Resolution", name: "board", docid: '14b3b123-c005-4b5b-b2f2-716051da27f6', value: '', fileId: '' ,mandatory:(entityType === 'Individual' || partnershipTypeLS?.includes("Sole")) ? false:true  },
    { inputLabel: "Additional Document", name: "additional3", docid: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '', fileId: '' },
    { inputLabel: "Additional Document", name: "additional4", docid: '10662581-4f7b-4c0d-918c-d937e1c9cf51', value: '', fileId: '' }
  ];
  
  const [companydocListState, setCompanyDocListState] = useState(companydocList);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any[]>([]);
  const [companyUploadedDoc, setCompanyUploadedDoc] = useState<any>({});
  const registrationId = getLocalStorage('registrationId');

  const { state } = useLocation();
  const [stepper, setStepper] = useState(STEPPER);
 
  const [stepCount, setStepCount] = useState(entityType === 'Individual' ? 3 : 4);
  const [errors, setErrors] = useState<any>({});
  const [directors, setDirectors] = useState<any>([]);


  
  useEffect(() => {
    let existingArray = JSON.parse(getLocalStorage("completed") || "[]");
    const entityType = getLocalStorage('Entity Type');
    const partnershipTypeLS = getLocalStorage('Partnership Type');
    const checkMandatoryFilesUploaded = () => {
      let allFilesUploaded = true;
  
      directors.forEach((_:any, directorIndex:any) => {
        docList.forEach((doc) => {
          if (doc.mandatory && (!uploadedDocuments[directorIndex] || !uploadedDocuments[directorIndex][doc.name]?.fileId)) {
            allFilesUploaded = false;
          }
        });
  
        docList2.forEach((doc:any) => {
          if (doc.mandatory && (!uploadedDocuments[directorIndex] || !uploadedDocuments[directorIndex][doc.name]?.fileId)) {
            allFilesUploaded = false;
          }
        });
      });
  
      companydocListState.forEach((doc) => {
        if (doc.mandatory && (!companyUploadedDoc[doc.name]?.fileId && !doc.value)) {
          allFilesUploaded = false;
        }
      });
      return allFilesUploaded;
    };
    if (entityType?.includes("Individual") && existingArray?.includes("individual") && existingArray?.includes("bank") && checkMandatoryFilesUploaded()) {
      setIsNextButtonDisabled(false);
    } else if (entityType?.includes("Business") && partnershipTypeLS?.includes("Proprietorship") && existingArray?.includes("individual") && existingArray?.includes("company") && existingArray?.includes("bank") && checkMandatoryFilesUploaded()) {
      setIsNextButtonDisabled(false);
    } else if (entityType?.includes("Business") && !partnershipTypeLS?.includes("Proprietorship") && existingArray?.includes("company") && existingArray?.includes("director") && existingArray?.includes("bank") && checkMandatoryFilesUploaded()) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [uploadedDocuments, companyUploadedDoc, directors, docList, docList2, companydocListState,isNextButtonDisabled]);
  
 

  useEffect(() => {
    if (entityType === 'Individual') {
      setStepper(STEPPER.filter(step => step !== 'Company Details'));
    } else if (entityType === 'Business Entity') {
      if (partnershipTypeLS === "Sole Proprietorship") {
        setStepper(STEPPER);
      } else {
        setStepper(STEPPER_INDIVIDUAL_DIRECTOR);
      }
    }
  }, [entityType]);
  const individual_id = getLocalStorage('individual_id');

  useEffect(() => {
    const entity = getLocalStorage('Entity Type');
    const partnershipType = getLocalStorage('Partnership Type');

    if (entity === "Business Entity" && partnershipType!=='Sole Proprietorship') {
      getDIRAPICall();
    }
    else {
      if (individual_id) {
        getIndividual()
      }
    }
  }, []);

  const getIndividual = async () => {
    showLoadingIndicator()
    const response = await get({
      url: getIndividualGETApiUrl(registrationId, individual_id)
    });
    if (response?.result) {
      hideLoadingIndicator();
      const { firstName, lastName } = response.result
      const defaultDirector: any = {
        directorId: getLocalStorage('individual_id'),
        firstName: firstName,
        lastName: lastName
      };
      setDirectors([defaultDirector]);
    }
    else {
      hideLoadingIndicator();
    }
  }


  useEffect(() => {
    if (directors.length > 0) {
      showLoadingIndicator();
      getAPICall();
      if (getLocalStorage('Entity Type') === "Business Entity") {
        getCompanyAPICall();
      }
    }
  }, [directors]);
  const getDIRAPICall = async () => {
    const response = await get({ url: getDirectorGETApiUrl(registrationId) });
    if (response?.result) {
      hideLoadingIndicator();
      const fetchedDirectors = response?.result?.map((director: any) => ({
        directorId: director.id,
        firstName: director.firstName || 'New Director',
        lastName: director.lastName,
      }));
      setDirectors(fetchedDirectors);
    } else {
      hideLoadingIndicator();
      showToast('Failed to load director information', 'error');
    }
  };
 
  const getAPICall = async () => {
    const response = await get({
      url: getPartnerKYCGETApiUrl(registrationId),
    });
    if (response?.result) {
      hideLoadingIndicator();

      const directorDocsMap: any = {};

      response.result.forEach((doc: any) => {
        const { directorId, fileTypeId, fileName, id, fileUrl, fileId } = doc;
        if (!directorDocsMap[directorId]) {
          directorDocsMap[directorId] = {};
        }
        directorDocsMap[directorId][fileTypeId] = {
          fileName,
          documentId: id,
          fileUrl,
          fileId,
        };
      });

      const updatedUploadedDocuments = directors.map((director: any) => directorDocsMap[director.directorId] || {});

      setUploadedDocuments(updatedUploadedDocuments);
    } else {
      hideLoadingIndicator();
      showToast('No documents found or failed to load data', 'error');
    }
  };
  const getCompanyAPICall = async () => {
    const response = await get({
      url: getCompanyKYCGETApiUrl(registrationId)
    });
    if (response?.result) {
      hideLoadingIndicator();
      const updatedCompanyDocList = companydocList.map((item) => {
        const resItem = response?.result?.length && response?.result?.find((res: any) => res.fileTypeId === item.docid);
        return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id, fileUrl: resItem.fileUrl, fileId: resItem.fileId } : item;
      });
      setCompanyDocListState(updatedCompanyDocList);
    }
    else {
      hideLoadingIndicator();
    }
  };
  const handleNext = async () => {
    showLoadingIndicator();
    try {
      const directorsPayload = directors.map((director: any, directorIndex: any) => ({
        directorId: director.directorId, 
        kyc: Object.entries(uploadedDocuments[directorIndex] || {}).map(([docName, doc]: any) => {
          return ({
            fileTypeId: docName, 
            fileId: doc.fileId,
            fileName: doc.fileName,
            fileSize: doc?.fileSize,
            fileType: doc?.fileType,
            status: 1,
            id: doc?.id
          })
        }),
      }));
      const companyDocuments = Object.values(companyUploadedDoc).filter((doc: any) => doc !== null);
      const companyPayload = companyDocuments.map((item: any) =>
      ({
        fileTypeId: item.fileTypeId,
        fileId: item.fileId,
        fileSize: item?.fileSize,
        fileType: item?.fileType,
        fileName: item?.fileName,
        status: 1,
        id: item?.id
      }));


      let obj = {
        "registrationId": registrationId,
        "directors": directorsPayload
      }
      let obj2 = {
        "registrationId": registrationId,
        "kyc": companyPayload
      }
      const response = await post({
        url: getPartnerKYCPostApiUrl(registrationId),
        obj: obj
      });
      if (response?.result) {
        hideLoadingIndicator();
        if ((getLocalStorage('Entity Type') === "Business Entity" && companyPayload)) {
          const response2 = await post({
            url: getCompanyKYCPostApiUrl(registrationId),
            obj: obj2
          });
          if (response2?.result) {
            hideLoadingIndicator();
            startTransition(() => {
              setStepCount(5);
            });
          }else if (response2?.error?.message) {
            showToast(response2?.error?.message, "error")
            hideLoadingIndicator();
          } else {
            showToast("something went wrong", "error")
            hideLoadingIndicator();
          }
        }
        if(response.result?.screenName === VERIFY_KYC){
        setIsSubmitted(true);
        startTransition(() => {
          setStepCount(5);
        });
        setLocalStorage("final", true)
      }else{
        showToast("Please retry after sometime", "error")
      }
      } else if (response?.error?.message) {
        showToast(response?.error?.message, "error")
        hideLoadingIndicator();
      } else {
        showToast("something went wrong", "error")
        hideLoadingIndicator();
      }

    } catch (error) {
      showToast("something went wrong", "error")
      hideLoadingIndicator();
      console.error("Failed to submit individual details:", error);
    }
  };

  const handleBack = () => {
    if (entityType.includes("Individual")) {
      setStepCount(1);
      navigate("/bank-details");
    } else if ((entityType.includes("Business")) && (partnershipTypeLS.includes("Proprietorship"))) {
      setStepCount(2);
      navigate("/bank-details");
    } else if ((entityType.includes("Business")) && (!partnershipTypeLS.includes("Proprietorship"))) {
      setStepCount(3);
      navigate('/bank-details')
    }
  };  
  const handleChange = async (fieldName: string, e: any, type: string, directorIndex?: number) => {
    const file = e.target.files?.[0];
    const fileTypeId = e.target.id;
    const documentId = e.target.getAttribute('data-document-id');
    const fileType = file.type;
    if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      showToast("Invalid file type. .docx files are not allowed.", "error");
      return;
    }
  
    if (file) {
      const response: any = await fileUpload(file);
  
      if (response?.result?.[0]?.file_id) {
        if (directorIndex !== undefined) {
          setUploadedDocuments((prevUploadedDocuments: any) => {
            const updatedDirectorDocs: any = [...(prevUploadedDocuments || [])];
  
            if (!updatedDirectorDocs[directorIndex]) {
              updatedDirectorDocs[directorIndex] = {};
            }
  
            updatedDirectorDocs[directorIndex][fieldName] = {
              fileSize: response.result[0].file_size,
              fileType: response.result[0].file_type,
              fileId: response.result[0].file_id,
              fileName: response.result[0].file_name,
              fileTypeId: fileTypeId,
              id: documentId || null,
            };
  
            return updatedDirectorDocs;
          });
        } else {
          setCompanyUploadedDoc((prevCompanyDocs: any) => {
            const updatedCompanyDocs = { ...(prevCompanyDocs || {}) }; 
            
            updatedCompanyDocs[fieldName] = {
              fileSize: response.result[0].file_size,
              fileType: response.result[0].file_type,
              fileId: response.result[0].file_id,
              fileName: response.result[0].file_name,
              fileTypeId: fileTypeId,
              id: documentId || null,
            };
  
            return updatedCompanyDocs;
          });
        }
      } else if (response?.error) {
        const error = fieldName === '' ? null : response?.error?.message;
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [fieldName]: error
        }));
        showToast(response?.error?.message,'error');
      } else {
        showToast("Something went wrong", "error");
      }
    }
  };
  
  const fileUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);
    const response: any = await uploadFileApiUrl(data);
    return response;
  };
  const handleClearkyc = (fieldName: any) => {
    setDocuments({
      ...documents,
      [fieldName]: ""
    });
    setErrors({
      ...errors,
      [fieldName]: null
    });
  };
  const handleClear = (fieldName: string, directorIndex: number) => {
    setUploadedDocuments((prev:any) => {
      if (!Array.isArray(prev)) return prev;
  
      const updatedDocuments = [...prev];
  
      if (updatedDocuments[directorIndex]) {
        updatedDocuments[directorIndex][fieldName] = {
          fileId: "",
          fileName: ""
        };
      }
      
      return updatedDocuments;
    });
  
    setDocuments((prevDocuments:any) => ({
      ...prevDocuments,
      [fieldName]: {
        fileId: "",
        fileName: ""
      }
    }));
  
    setErrors((prevErrors:any) => ({
      ...prevErrors,
      [fieldName]: null
    }));
  };
  useEffect(() => {
  }, [isNextButtonDisabled]);

  useEffect(() => {
    if (state) {
      const { registerType, enityType, PartnershipType } = state;
      if (registerType === RegisterTypeOptions[0]?.label && enityType === EntityTypeOptions[0]?.label) {
        setStepper(STEPPER_INDIVIDUAL);
      } else if (registerType === RegisterTypeOptions[0]?.label && (PartnershipType === PartnerShipTypeOptions[1]?.label || PartnershipType === PartnerShipTypeOptions[5]?.label)) {
        setStepper(STEPPER_INDIVIDUAL_DIRECTOR);
      }
    }
  }, [stepper]);



  const mobile = window.innerWidth < 768;
  return (
    <Layout>
      {!mobile && <CustomStepper steps={stepper} stepCount={stepCount} />}
      {visible ?
        <>{loadingComponent()}</> :
        <>   {!isSubmitted ? (
          <div>

            <div css={containerStyle}>
              {KYC_DOCUMENTS}
            </div>
            <hr css={breakLine} />
            <div css={kycSubHeading}>
              {KYC_VERIFICATION_HEADING}
            </div>
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
              <Grid container spacing={2} css={gridContainerStyle}>
                {directors.map((director: any, directorIndex: any) => (
                  <div key={directorIndex}>
                    <h3>{`${director.firstName} ${director.lastName}`} Documents</h3>
                    <Grid container spacing={2}>
                      {docList.map((doc, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <InputFile
                            key={index}
                            inputLabel={doc.inputLabel}
                            onChange={(e) => handleChange(doc.name, e, "partner", directorIndex)}
                            css={formControlStyle}
                            onClear={() => handleClear(doc.name, directorIndex)}
                            docId={doc.docid}
                            fileName={uploadedDocuments[directorIndex]?.[doc.name]?.fileName || doc.value}
                            value={uploadedDocuments[directorIndex]?.[doc.name]?.fileName || ''}
                            mandatory={doc.mandatory}
                          />
                          {uploadedDocuments[directorIndex]?.[doc.name]?.fileId && (
                            <PreviewComponent file={uploadedDocuments[directorIndex]?.[doc.name]?.fileId} />
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid container spacing={2}>
                      {docList2.map((doc, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <InputFile
                            inputLabel={doc.inputLabel}
                            onChange={(e) => handleChange(doc.name, e, 'partner', directorIndex)}
                            css={formControlStyle}
                            fileName={uploadedDocuments[directorIndex]?.[doc.name]?.fileName || doc.value}
                            value={uploadedDocuments[directorIndex]?.[doc.name]?.fileName || ''}
                            docId={doc.docid}
                            onClear={() => handleClear(doc.name, directorIndex)}
                            mandatory={doc.mandatory}
                          />
                          {uploadedDocuments[directorIndex]?.[doc.name]?.fileId && (
                            <PreviewComponent file={uploadedDocuments[directorIndex]?.[doc.name]?.fileId} />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                    {getLocalStorage('Entity Type') === "Business Entity" ? <hr css={breakLine} /> : null}
                  </div>
                ))}
              </Grid>
            </Box>
            {(getLocalStorage('Entity Type') === "Business Entity") && (
              <>
                <div css={kycSubHeading}>
                  {KYC_VERIFICATION_COMPANY_HEADING}
                </div>
                <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                  <Grid container spacing={2} css={gridContainerStyle2}>
                    {companydocListState.map((item: any, index: any) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <InputFile
                          key={index}
                          inputLabel={item.inputLabel}
                          onChange={(e) => handleChange(item.name, e, "company")}
                          css={formControlStyle}
                          onClear={() => handleClearkyc(item.name)}
                          docId={item.docid}
                          value={item.value}
                          documentId={item.documentId}
                          fileName={companyUploadedDoc[item.name]?.fileName || item.value}
                          mandatory={item.mandatory}
                        />
                        {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                        {(companyUploadedDoc[item.name]?.fileId || item?.fileId) && <PreviewComponent file={companyUploadedDoc[item.name]?.fileId || item?.fileId} />
                        }
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
         
            <div css={registrationButtonContainer}>
              <CustomButton
                label={BACK_LABEL}
                onClick={handleBack}
                variant="outlined"
                css={regBackButton}
                disabled={stepCount === 0}
              />
              <CustomButton
                label={SUBMIT_LABEL}
                onClick={handleNext}
                css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}

              />
            </div>
          </div>) :
          (<div>
            <div css={containerStyle}>
              {SUBMITTED_SUCCESS_HEADING}
            </div>
            <hr css={breakLine} />
            <div>
              <img src={SuccessGiff} alt="no_success_img" />
            </div>
            <div css={submitGetBackText}>
              Your application <h3>{getLocalStorage('application_number')}</h3>has submitted to us. We will connect back with you shortly!
            </div>
          </div>)

        }

        </>}
      <ToastContainer />
    </Layout>
  );
};

export default KycDocumentes;