/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { regBackButton, registrationButtonContainer, regNextButton, regNextButtonDisabled } from '../Registration/Registration.style';
import CustomButton from '../../components/Button/Button';
import { getEsignGETApiUrl, getEsignPostApiUrl, uploadFileApiUrl } from '../../apiManger/apiConstants';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import { get, post } from '../../apiManger/apiMethods';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../components/Loader/Loder';
import { useNavigate } from 'react-router-dom';
import PreviewComponent from '../KycDocumentes/Preview';
import { StringConstants } from '../../constants/Strings';
import { esignSection, marginLeftStyle, fileListContainer, fileItems, fileName, multifileSection, buttonSection, UploadCustomButton, VisuallyHiddenInput, fileContainer, fileItem } from './Esign.style';


const MultiFileUpload = (props: any) => {
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();

  const { showToast } = useToastHook()
  const {SCREEN_NAME:{EMPANELMENT_CODE}}= StringConstants;
  const { setShowDetails, detailsData } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showEsign, setShowEsign] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (detailsData?.esignedDocument) {
      setShowEsign(true);
    }
  }, [])
  const handleClick = () => {
  
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file)
      fileUpload(file);
  };


  const fileUpload = async (file: File | null) => {
    if (!file) return;

    const data = new FormData();
    data.append("file", file);

    const response: any = await uploadFileApiUrl(data);

    if (response?.result?.[0]?.file_id) {
      const fileDetails = {
        fileId: response.result[0].file_id,
        fileName: response.result[0].file_name,
        fileType: response.result[0].content_type,
        fileSize: response.result[0].file_size,
        public_access: response.result[0].public_access,
        registrationId: getLocalStorage('Application_registrationId'),
        fileTypeId: '4ce76767-3999-4ceb-af72-a7ba96ac8f2d',
        status: 1,
        id: null
      };
      setUploadedFiles((prevFiles: any) => [...prevFiles, fileDetails]);
    } else if (response?.error?.message) {
      showToast(response.error.message, "error");
    } else {
      showToast("Something went wrong", "error");
    }
  };

  const handleDelete = (index: number) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  useEffect(() => {
    getAPICall()
  }, [])
  const registrationId = getLocalStorage('Application_registrationId');

  const getAPICall = async () => {
    showLoadingIndicator();
    const response: any = await get({
      url: getEsignGETApiUrl(registrationId)
    });
    if (response?.result) {
      hideLoadingIndicator();
      setUploadedFiles(response.result)
    }
    else {
      hideLoadingIndicator();
    }
  };
  const handleNext = async () => {
    showLoadingIndicator();
    try {
      let response;
      response = await post({
        url: getEsignPostApiUrl(registrationId),
        obj: uploadedFiles
      });
      if (response?.result) {
        if (response.result.screenName === EMPANELMENT_CODE) {
          navigate('/empanelment');
          hideLoadingIndicator();
        }
        else {
          showToast("Please retry after sometime", "error")
          hideLoadingIndicator()
        }
      }
      else {
        hideLoadingIndicator()
        showToast("something went wrong", "error")
      }
    }
    catch (error) {
      showToast("something went wrong", "error")

    }
  }

  return (
    <>
      {visible ? (
        <>{loadingComponent()}</>
      ) : (<>  {showEsign && (
        <div css={esignSection} >
          <div css={marginLeftStyle}>Signed Document</div>
          <div css={fileListContainer} >
            <div css={fileItems}>
              <span css={fileName}>{detailsData?.esignedDocument?.fileName}</span>
              <PreviewComponent file={detailsData?.esignedDocument} />
            </div>
          </div>
        </div>
      )} <div css={multifileSection}>
        <div css={buttonSection}>
       
          <UploadCustomButton
            onClick={handleClick}
            variant="outlined"
            startIcon={
              <SvgIcon sx={{ color: '#e12c36' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </SvgIcon>
            }
          >
            Upload file
          </UploadCustomButton>
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileUpload}
          />
        </div>
        <div css={fileListContainer}>
       
          {uploadedFiles.map((file: any, index: number) => (
             <div key={index} css={fileContainer}>
             <div css={uploadedFiles?.length === 1 ? fileItem : fileItems}>
               <span css={fileName}>{file.fileName}</span>
               <IconButton size="small" onClick={() => handleDelete(index)}>
                 <CloseIcon fontSize="small" />
               </IconButton>
             </div>
             {file?.fileId && <PreviewComponent file={file?.fileId} />}
           </div>
          ))}

        </div>
       
      </div>
        <div css={registrationButtonContainer}>
          <CustomButton
            label="Back"
            onClick={() => setShowDetails(false)}
            variant="outlined"
            css={regBackButton}
          />
          <CustomButton
            label="Next"
            onClick={() => handleNext()}
            css={uploadedFiles?.length || detailsData?.applicationProgress?.includes("e-signComplete") ? regNextButton : regNextButtonDisabled}
          />
          <ToastContainer />
        </div></>)}
    </>
  );
};

export default MultiFileUpload;
