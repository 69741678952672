import { css } from '@emotion/react';
import { colors, typography } from '../../../../scss/colors';

export const containerStyle = css`
            display: flex;
            align-items: center;
            width: 100%;
            color: ${colors.charcoal};
            ${typography('600', '20px')};
             @media(max-width:768px){
  flex-direction: column;
  gap:10px;
  }
          `;

export const directorSection = css`
            width: 50%;
            display: flex;
            justify-content: flex-end;
             @media(max-width:768px){
  width:100%;
  justify-content:center;
  }
          `;

export const directorContainer = css`
            margin-bottom: 40px;
            margin-top: 20px;
          `;