import { css } from "@emotion/react";
import { colors, typography } from "../../scss/colors";

export const containerStyle = css`
  color: ${colors.charcoal};
  ${typography('600', '20px')};
`;

export const breakLine = css`
  border: 1px solid rgba(100, 130, 130, 0.25);
  margin-bottom: 25px;
`;





export const kycSubHeading = css`
  color: ${colors.charcoal};
  ${typography('600', '16px')}
  display: flex;
  justify-content: flex-start;
margin-bottom:20px;
`;

export const submitGetBackText = css`
  color: ${colors.charcoal};
  ${typography('400', '16px')};
  line-height: 20.16px;
  margin-top: 50px;
 display: flex;
    justify-content: center;
    align-items: center;
     h3 {
     padding:0px 10px;
}

`;

export const errorMsg = css`
display:flex;
justify-content:start;
 color: ${colors.crimson};
  ${typography('500', '14px')};
`
export const gridContainerStyle = css`
width: calc(100% + 30px);
display:contents;
@media(max-width:768px){
width: calc(100% + 20px);
}
`;

export const gridContainerStyle2 = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
export const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;