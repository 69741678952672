/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useToastHook } from '../../../components/Toast/useToastHook';
import Loder from '../../../components/Loader/Loder';
import { StringConstants } from '../../../constants/Strings';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { getIDVerificationGETApiUrl } from '../../../apiManger/apiConstants';
import { get } from '../../../apiManger/apiMethods';
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { renderVerificationStatus } from '../../../components/RenderVerification/RenderVerification';
import { positionStyle } from '../../../components/Accordian/AccordianContentForm';
import { containerStyle,directorSection,formControlStyle,gridContainerStyle } from './styles/IdVerification.style';

const IdVerification = (props: any) => {
    const { showToast } = useToastHook();
    const { setValue, value } = props;
    const filterByStatus = getLocalStorage('filterByStatus');
    const { BACK_LABEL, NEXT_LABEL } = StringConstants;

    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();

    const handleNext = async () => {
        showLoadingIndicator();
        try {
            hideLoadingIndicator();
            setValue(value + 1);
        } catch (error) {
            hideLoadingIndicator();
            showToast('Something went wrong', 'error');
        }
    };

    const handleBack = () => {
        setValue((prev: any) => prev - 1);
    };


    const [directorsVal, setDirectorsVal] = useState<any>([]);
    useEffect(() => {
        getAPICall()
    }, [])
    const registrationId = getLocalStorage('Application_registrationId')
    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getIDVerificationGETApiUrl(registrationId),
        });
        if (response?.result) {
            hideLoadingIndicator();
            setDirectorsVal(response?.result);
        } else {
            hideLoadingIndicator();
        }
    };
    const latestEntityType = getLocalStorage('Application_EntityType');

    const marginTopStyle = css`
    margin-top:10px
    `
    return (
        <div>
            {visible ? (
                <>{loadingComponent()}</>
            ) : (
                <>
                    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                        <Grid container spacing={2} css={gridContainerStyle}>
                            {directorsVal.directors?.map((director: any, index: any) => (
                                <Grid container spacing={2} key={index}>
                                    <div css={containerStyle}>
                                        <div css={directorSection}>
                                            Name: {director.name}
                                        </div>  </div>
                                    <Grid item xs={12} sm={6} md={6} css={positionStyle}>
                                        <div >
                                            <CustomInput
                                                inputLabel={`Individual PAN`}
                                                value={director.pan}
                                                type="text"
                                                css={formControlStyle}
                                                required
                                                placeholder="Individual PAN"
                                                disabled
                                                customStyles={{ width: '100%' }}
                                                fullWidth
                                            />
                                            {director.panStatus && renderVerificationStatus(director.panStatus === "VALID" ? true : false)}
                                        </div>


                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} css={positionStyle}>
                                        <div><CustomInput
                                            inputLabel={`Aadhaar`}
                                            value={director.aadhaar}
                                            type="text"
                                            css={formControlStyle}
                                            required
                                            placeholder="Aadhaar"
                                            disabled
                                            customStyles={{ width: '100%' }}
                                            fullWidth
                                        />
                                            {director.aadhaarStatus && renderVerificationStatus(director.aadhaarStatus === "VALID" ? true : false)}
                                        </div>
                                    </Grid>
                                    {director.emailStatus && (
                                        <Grid item xs={12} sm={6} md={6} css={positionStyle}>
                                            <div><CustomInput
                                                inputLabel={`Email Id`}
                                                value={director.email}
                                                type="text"
                                                css={formControlStyle}
                                                required
                                                placeholder="Email Id"
                                                disabled
                                                customStyles={{ width: '100%' }}
                                                fullWidth
                                            />
                                                {renderVerificationStatus(director.emailStatus === "VALID" ? true : false)}
                                            </div>

                                        </Grid>
                                    )}
                                </Grid>
                            ))}

                            <Grid container spacing={2} css={marginTopStyle}>
                                {latestEntityType.includes("Business") && (
                                    <Grid item xs={12} sm={6} md={6} css={positionStyle}>
                                        <div> <CustomInput
                                            inputLabel="Business PAN"
                                            value={directorsVal?.businessPan}
                                            type="text"
                                            css={formControlStyle}
                                            required
                                            placeholder="Business PAN"
                                            disabled
                                            fullWidth
                                        />
                                            {directorsVal?.businessPanStatus && renderVerificationStatus(directorsVal?.businessPanStatus === "VALID" ? true : false)}
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} md={6} css={positionStyle}>
                                    <div><CustomInput
                                        inputLabel="Bank Account"
                                        value={directorsVal?.bankAccount}
                                        type="text"
                                        css={formControlStyle}
                                        required
                                        placeholder="Bank Account"
                                        disabled
                                        fullWidth
                                    />
                                        {directorsVal.bankAccountStatus && renderVerificationStatus(directorsVal.bankAccountStatus === "VALID" ? true : false)}
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>


                    <div css={registrationButtonContainer}>
                        <CustomButton
                            label={BACK_LABEL}
                            onClick={handleBack}
                            variant="outlined"
                            css={regBackButton}
                        />
                        <CustomButton
                            label={NEXT_LABEL}
                            onClick={handleNext}
                            css={filterByStatus?.toUpperCase() !== StringConstants.APPROVED_STATE ? regNextButton : regNextButtonDisabled}
                        />
                    </div>
                    <ToastContainer />
                </>
            )}
        </div>
    );
};

export default IdVerification;
