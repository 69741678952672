/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Button/Button';
import { colors } from '../../../scss/colors';
import { css } from '@emotion/react';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import InputFile from '../../../components/InputFile/InputFile';
import CustomSelect from '../../../components/Select/Select';
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { getUploadPayoutDocsGETApiUrl, getUploadPayoutDocsPostApiUrl, uploadFileApiUrl } from '../../../apiManger/apiConstants';
import { get, post } from '../../../apiManger/apiMethods';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from '../../KycDocumentes/Preview';
import Loder from '../../../components/Loader/Loder';
import { Box, Grid } from '@mui/material';
import { actionButtons } from './InitiateCpv.style';

const UploadCpv = (props: any) => {
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const { showToast } = useToastHook()
    const [errors, setErrors] = useState<any>({});
    const { setValue, handleUploadNext } = props;
    const filterByStatus = getLocalStorage('filterByStatus')
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const { BACK_LABEL, NEXT_LABEL,SCREEN_NAME:{PAYOUT_PARTNERPORTAL,SANCTION_ONE,SANCTION_TWO} } = StringConstants;
    const [documents, setDocuments] = useState<any>({
        fcu: "",
        cpv: "",
        fcuStatus: "",
        cpvStatus: ""
    });

    const handleClear = (fieldName: any) => {
        setDocuments({
            ...documents,
            [fieldName]: ""
        });
        setErrors({
            ...errors,
            [fieldName]: null
        });
    };

    useEffect(() => {
        const allFieldsFilled = Object.values(documents).every(value => value && value !== "");
        setIsNextButtonDisabled(!allFieldsFilled);
    }, [documents]);

    const registrationId = getLocalStorage('Application_registrationId');

    useEffect(() => {
        getAPICall();
    }, [])
    const getAPICall = async () => {
        try {
            const response = await get({
                url: getUploadPayoutDocsGETApiUrl(registrationId)
            });
            if (response && response.result) {


                setDocuments({
                    id: response.result.id,
                    fcu: {
                        id: response.result.fcuDocumentId,
                        name: response.result.fcuFileName,
                        url: response.result.fcuDocumentUrl,
                    },
                    cpv: {
                        id: response.result.cpvDocumentId,
                        name: response.result.cpvFileName,
                        url: response.result.cpvDocumentUrl,
                    },
                    fcuStatus: response.result.fcuStatus,
                    cpvStatus: response.result.cpvStatus
                });
            }
        } catch (error) {
            showToast("something went wrong", "error")
            console.error("Error fetching documents data:", error);
        }
    };
    const handleNext = async () => {
        showLoadingIndicator()
        try {
            let payload: any;
            if (documents.id !== '') {
                payload = {
                    registrationId,
                    id: documents.id,
                    fcuDocumentId: documents.fcu.id,
                    fcuFileTypeId: documents.fcu?.fileTypeId,
                    fcuFileSize: documents.fcu?.file_size,
                    fcuFileType: documents.fcu?.file_type,
                    fcuFileName: documents.fcu.name,
                    fcuStatus: documents.fcuStatus,


                    cpvDocumentId: documents.cpv.id,
                    cpvFileTypeId: documents.cpv?.fileTypeId,
                    cpvFileType: documents.cpv?.file_type,
                    cpvFileSize: documents.cpv?.file_size,
                    cpvFileName: documents.cpv.name,
                    cpvStatus: documents.cpvStatus,
                    status: 1,
                };
            }
            else {
                payload = {
                    registrationId,
                    fcuDocumentId: documents.fcu.id,
                    fcuFileTypeId: documents.fuc?.fileTypeId,
                    fcuFileSize: documents.fuc?.file_size,
                    fcuFileType: documents.fuc?.file_type,
                    fcuFileName: documents.fcu.name,
                    fcuStatus: documents.fcuStatus,


                    cpvDocumentId: documents.cpv.id,
                    cpvFileTypeId: documents.cpv?.fileTypeId,
                    cpvFileType: documents.cpv?.file_type,
                    cpvFileSize: documents.cpv?.file_size,
                    cpvFileName: documents.cpv.name,
                    cpvStatus: documents.cpvStatus,
                    status: 1,
                };
            }

            const response = await post({
                url: getUploadPayoutDocsPostApiUrl(registrationId),
                obj: payload
            });

            if (response?.result?.screenName === PAYOUT_PARTNERPORTAL) {
                hideLoadingIndicator()
                handleUploadNext()
            }
            else if (response?.result?.screenName === SANCTION_ONE || response?.result?.screenName === SANCTION_TWO) {
                hideLoadingIndicator()
                handleUploadNext()
            } else if (response?.error?.message) {
                hideLoadingIndicator()
                showToast(response?.error?.message, "error")
            }
            else {
                hideLoadingIndicator()
                showToast("something went wrong", "error")
            }
        } catch (error) {
            hideLoadingIndicator()
            showToast("something went wrong", "error")
            console.error('Error occurred while submitting KYC details:', error);
        }

    };

    const statusOptions = [
        { value: 'Positive', label: 'Positive' },
        { value: 'Negative', label: 'Negative' },
        { value: 'Refer', label: 'Refer' },
    ];

    const Cpv = [
        { inputLabel: "FCU Document", name: "fcu", type: "file", id: "20e1a4f5-f8a7-4165-bf1e-856ddc7cebef" },
        { inputLabel: "FCU Status", name: "fcuStatus", type: "select" },
        { inputLabel: "CPV Document", name: "cpv", type: "file", id: "98a3b956-c005-4b5b-a2e2-716051da27f6" },
        { inputLabel: "CPV Status", name: "cpvStatus", type: "select" }
    ];
    const sanction = [
        { inputLabel: "FCU Document", name: "fcu", type: "file", id: "20e1a4f5-f8a7-4165-bf1e-856ddc7cebef" },
        { inputLabel: "FCU Status", name: "fcuStatus", type: "select" },
        { inputLabel: "CPV Document", name: "cpv", type: "file", id: "98a3b956-c005-4b5b-a2e2-716051da27f6" },
        { inputLabel: "CPV Status", name: "cpvStatus", type: "select" }
    ];
    const data = props?.cpvDetails === "sanction" ? sanction : Cpv;

    const handleChange = async (fieldName: any, e: any, type: any) => {

        if (type === "file") {
            showLoadingIndicator()
            const file = e.target.files?.[0];
            const fileType = file?.type;
            const fileTypeId = e.target.id;
            if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                showToast("Invalid file type .docx files are not allowed.", "error");
                return;
            }
            if (file) {
                const response = await fileUpload(file);
                if (response?.result?.[0]?.file_id) {
                    hideLoadingIndicator()
                    setDocuments((prev: any) => ({
                        ...prev,
                        [fieldName]: {
                            id: response?.result?.[0]?.file_id,
                            name: response?.result?.[0]?.file_name,
                            ...response?.result?.[0],
                            fileTypeId: fileTypeId
                        }
                    }));
                } else if (response?.error) {
                    hideLoadingIndicator()
                    const error = fieldName === '' ? null : response?.error?.message;
                    setErrors({
                        ...errors,
                        [fieldName]: error
                    });
                } else {
                    hideLoadingIndicator()
                    showToast("Something went wrong", "error");
                }

            }
        } else {
            hideLoadingIndicator()
            setDocuments((prev: any) => ({
                ...prev,
                [fieldName]: e.target.value
            }));
        }
    };

    const fileUpload = async (file: any) => {
        const data = new FormData();
        data.append("file", file);
        const response: any = await uploadFileApiUrl(data)
        return response;
    };
    const gridContainerStyle = css`
    width: calc(100% + 30px);
    @media(max-width:768px){
    width: calc(100% + 20px);
    }
    `;
    const formControlStyle = css`
    margin-bottom: 16px;
    width: 100%;
    `;
    return (
        <>
            {visible ?
                <>{loadingComponent()}</> :
                <div>
                    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                        <Grid container spacing={2} css={gridContainerStyle}>
                            {data.map((item: any, index: any) => (
                                <>
                                    {item.type === "file" ? (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <InputFile
                                                inputLabel={item.inputLabel}
                                                onChange={(e) => handleChange(item.name, e, "file")}
                                                css={formControlStyle}
                                                mandatory={true}
                                                onClear={() => handleClear(item.name)}
                                                registration={false}
                                                docId={item.id}
                                                value={documents[item.name].id ? documents[item.name].name : ""}
                                                documentId={documents[item.name].id}
                                                fileName={documents[item.name]?.name}
                                            />
                                            {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                                            {(documents[item.name]?.file_id || documents[item.name]?.id) && <PreviewComponent file={documents[item.name]?.file_id || documents[item.name]?.id} />}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <CustomSelect
                                                inputLabel={item.inputLabel}
                                                value={documents[item.name]}
                                                onChange={(e) => handleChange(item.name, e, "select")}
                                                options={statusOptions}
                                                placeholder="Please Select"
                                                css={formControlStyle}
                                                mandatory={true}
                                            />
                                        </Grid>
                                    )}
                                </>
                            ))}
                        </Grid>
                    </Box>
                    {props?.cpvDetails === "cpvDetails" ?
                        <div css={registrationButtonContainer}>
                            <div css={actionButtons}>
                                <CustomButton
                                    label={BACK_LABEL}
                                    onClick={() => setValue((pre: any) => pre - 1)}
                                    variant="outlined"
                                    css={regBackButton}
                                />
                                <CustomButton
                                    label="Next"
                                    onClick={() => setValue(4)}
                                    css={(!isNextButtonDisabled && filterByStatus?.toUpperCase() !== StringConstants.APPROVED_STATE) ? regNextButton : regNextButtonDisabled}
                                />
                            </div>
                        </div>
                        :
                        <div css={registrationButtonContainer}>
                            <CustomButton
                                label={BACK_LABEL}
                                onClick={() => setValue((pre: any) => pre - 1)}
                                variant="outlined"
                                css={regBackButton}
                            />
                            <CustomButton
                                label={NEXT_LABEL}
                                onClick={handleNext}
                                css={(!isNextButtonDisabled && filterByStatus?.toUpperCase() !== StringConstants.APPROVED_STATE) ? regNextButton : regNextButtonDisabled}
                            />
                        </div>
                    }
                    <ToastContainer />
                </div>
            }
        </>
    );
}

export default UploadCpv;
